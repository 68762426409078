import { SVGProps } from "react";

export default function IcMenu({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5625 12H19.4375M4.5625 6H19.4375M4.5625 18H19.4375"
        stroke="currentColor"
        stroke-width="1.3"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  );
}
