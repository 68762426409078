import { useContext, useState } from "react";
import scheduleContext from "crm/schedule/scheduleContext";

import { css } from "@emotion/react";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";

import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";
import cardIcon from "design-system/components/atom/IconResource/ic_card.svg";
import calendarIcon from "design-system/components/atom/IconResource/ic_calender.svg";
import leftArrowIcon from "design-system/components/atom/IconResource/ic_arrow_left.svg";
import rightArrowIcon from "design-system/components/atom/IconResource/ic_arrow_right.svg";
import infoIcon from "design-system/components/atom/IconResource/info.png";

import Button from "design-system/components/Button/Button";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { FitsYouDatePicker } from "design-system/components/FitsYouDatePicker/FitsYouDatePicker";
import * as Select from "design-system/components/Select/Select";
import * as Dialog from "design-system/components/Dialog/Dialog";
import SelectSearchBar from "react-select";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient, queryKeys } from "libs/react-query";
import { getUsers } from "api/trainer/users/users";
import { postSchedules } from "api/trainer/schedules/schedules";
import { RepeatType } from "api/common/commonEnumType";
import { UserInfoOfTrainerDTO } from "api/trainer/users/usersTypes";
import { format } from "date-fns";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import { CustomFetchError } from "libs/fetch/fetch";

interface CreateSidebarProps {
  date: Date;
}

export function CreateSidebar({ date }: CreateSidebarProps) {
  const { scheduleType, setSidebarState } = useContext(scheduleContext);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const [startAt, setStartTime] = useState<Date | null>(selectedDate);
  const [endAt, setEndTime] = useState<Date | null>(selectedDate);

  const [searchUser, setSearchUser] = useState<UserInfoOfTrainerDTO | null>();
  const [selectedTicketIdx, setSelectedTicketIdx] = useState(0);

  const [repeatType, setRepeatType] = useState<RepeatType>();
  const [location, setLocation] = useState("");
  const [memo, setMemo] = useState("");
  const [title, setTitle] = useState("");

  const { data: user } = useQuery({
    queryFn: () => getUsers(),
    queryKey: queryKeys.getUsers(),
  });

  const { mutate: createSchedule } = useMutation({
    mutationFn: postSchedules,
    onSuccess: () => {
      setSidebarState(undefined);
      queryClient.invalidateQueries([
        queryKeys.getSchedules({
          year: selectedDate?.getFullYear() ?? 0,
          month: selectedDate?.getMonth() ?? +1,
        })[0],
      ]);
    },
    onError: (error: CustomFetchError) => {
      if (error.data?.code === 409102) {
        setIsErrorDialogOpen(true);
      }
    },
    useErrorBoundary: (error: CustomFetchError) => error.status !== 409,
  });

  // TODO: css @sello
  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    // ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <div css={datePickerInputContainerCSS}>
        <img src={calendarIcon} alt={"calendar"} css={calendarIconCSS} />
        <input css={datePickerInputCSS} {...props} />
      </div>
    );
  };

  // TODO: css detail check @sello

  return (
    <div css={containerCSS}>
      <div css={contentContainerCSS}>
        <div css={closeButtonContainerCSS}>
          <Button
            variant="icon"
            onClick={() => {
              setSidebarState(undefined);
            }}
          >
            <IcClose />
          </Button>
        </div>
        {scheduleType.toUpperCase() === "ETC" && (
          <div css={infoContainerCSS}>
            <span css={infoTitleCSS}>
              제목
              <span style={{ color: `${colors.orange}` }}>*</span>
            </span>
            <Textarea
              placeholder="제목을 입력해주세요"
              styles={textareaCSS}
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              maxLength={30}
              isPreventNewline
            />
          </div>
        )}
        <div css={infoContainerCSS}>
          <span css={infoTitleCSS}>
            날짜
            <span style={{ color: `${colors.orange}` }}>*</span>
          </span>
          <FitsYouDatePicker
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onChange={setSelectedDate}
            customInput={<CustomInput />}
            onSelect={() => setIsCalendarOpen(false)}
          />
        </div>
        <div css={rowInfoContainerCSS}>
          <div css={infoContainerCSS}>
            <span css={infoTitleCSS}>
              시작 시간<span style={{ color: `${colors.orange}` }}>*</span>
            </span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimeField value={startAt} onChange={setStartTime} />
            </LocalizationProvider>
          </div>
          <div css={infoContainerCSS}>
            <span css={infoTitleCSS}>
              종료 시간<span style={{ color: `${colors.orange}` }}>*</span>
            </span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimeField value={endAt} onChange={setEndTime} />
            </LocalizationProvider>
          </div>
        </div>
        {scheduleType.toUpperCase() === "PT" && (
          <>
            <div css={infoContainerCSS}>
              <span css={infoTitleCSS}>
                회원명<span style={{ color: `${colors.orange}` }}>*</span>
              </span>
              <SelectSearchBar
                className="select-search-bar"
                classNamePrefix="select"
                isSearchable
                placeholder="회원이름을 기입해주세요"
                value={searchUser}
                onChange={setSearchUser}
                options={user?.content}
                getOptionLabel={(option) =>
                  `${option.userInfo.name} (${option.userInfo.phone})`
                }
                getOptionValue={(option) => option.userInfo.userId.toString()}
              />
            </div>
            <div css={infoContainerCSS}>
              <span css={infoTitleCSS}>
                수강권 선택<span style={{ color: `${colors.orange}` }}>*</span>
              </span>
              <div css={traineeTicketBoxContainerCSS}>
                {searchUser?.tickets?.length ? (
                  <>
                    {selectedTicketIdx > 0 && (
                      <Button
                        variant="icon"
                        onClick={() =>
                          setSelectedTicketIdx(selectedTicketIdx - 1)
                        }
                      >
                        <div css={traineeTicketBoxLeftArrowContainerCSS}>
                          <img
                            src={leftArrowIcon}
                            alt={"left arrow"}
                            css={traineeTicketBoxArrowIconCSS}
                          />
                        </div>
                      </Button>
                    )}
                    <div
                      css={traineeTicketCarouselCSS}
                      style={{ translate: `-${240 * selectedTicketIdx}px` }}
                    >
                      {searchUser?.tickets.map((ticket) => (
                        <div css={traineeTicketBoxCSS}>
                          <div css={traineeTicketCSS}>
                            <div css={traineeTicketActivityBadgeCSS}>
                              {ticket.status === "active" ? "활성" : "만료"}
                            </div>
                            <div css={traineeTickeDescContainerCSS}>
                              <span css={traineeTicketDescCountTextCSS}>
                                {`${ticket.usedCount}/${ticket.totalCount}회`}
                              </span>
                              <span css={traineeTicketDescDateTextCSS}>
                                {`${ticket.startAt} ~ ${ticket.endAt || "종료 제한 없음"}`}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {selectedTicketIdx < searchUser?.tickets.length - 1 && (
                      <Button
                        variant="icon"
                        onClick={() =>
                          setSelectedTicketIdx(selectedTicketIdx + 1)
                        }
                      >
                        <div css={traineeTicketBoxRightArrowContainerCSS}>
                          <img
                            src={rightArrowIcon}
                            alt={"right arrow"}
                            css={traineeTicketBoxArrowIconCSS}
                          />
                        </div>
                      </Button>
                    )}
                  </>
                ) : (
                  <div css={traineeTicketPlaceHolderBoxCSS}>
                    <div css={traineeTicketIconContainerCSS}>
                      <img
                        src={cardIcon}
                        alt={"card icon"}
                        css={traineeTicketIconCSS}
                      />
                    </div>
                    <span css={traineeTicketPlaceHolderDescCSS}>
                      회원권이 있는 회원을 선택해주세요
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div css={infoContainerCSS}>
          <span css={infoTitleCSS}>반복 설정</span>
          <Select.Root
            defaultValue={repeatType}
            onValueChange={(value: RepeatType) => setRepeatType(value)}
          >
            <Select.Trigger>
              <Select.Value placeholder="반복 안함" />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value="null">반복 안함</Select.Item>
              <Select.Item value="daily">매일</Select.Item>
              <Select.Item value="weekly">매주</Select.Item>
              <Select.Item value="monthly">매월</Select.Item>
            </Select.Content>
          </Select.Root>
        </div>
        <div css={infoContainerCSS}>
          <span css={infoTitleCSS}>장소</span>
          <Textarea
            placeholder="장소를 입력해주세요"
            styles={textareaCSS}
            value={location}
            onChange={(event) => {
              setLocation(event.target.value);
            }}
            maxLength={30}
            isPreventNewline
          />
        </div>
        <div css={infoContainerCSS}>
          <span css={infoTitleCSS}>메모</span>
          <Textarea
            placeholder="메모할 내용을 입력해주세요"
            styles={memoTextareaCSS}
            value={memo}
            onChange={(event) => {
              setMemo(event.target.value);
            }}
          />
        </div>
      </div>
      <div css={buttonContainerCSS}>
        {new Date(startAt ?? "") < new Date(endAt ?? "") ? (
          <Button
            variant="primary"
            styles={createButtonCSS}
            onClick={() => {
              if (scheduleType.toUpperCase() === "PT") {
                createSchedule({
                  userId: searchUser?.userInfo.userId,
                  ticketId: searchUser?.tickets[selectedTicketIdx].id,
                  memo,
                  startAt: `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(startAt ?? "", "HH:mm")}`,
                  endAt: `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(endAt ?? "", "HH:mm")}`,
                  scheduleType,
                  repeatType,
                  location,
                });
              } else if (scheduleType.toUpperCase() === "ETC") {
                createSchedule({
                  title,
                  memo,
                  startAt: `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(startAt ?? "", "HH:mm")}`,
                  endAt: `${format(selectedDate ?? "", "yyyy-MM-dd")}T${format(endAt ?? "", "HH:mm")}`,
                  scheduleType,
                  repeatType,
                  location,
                });
              }
            }}
            // TODO: 유효성 체크 개선 @sello
            disabled={
              (scheduleType.toUpperCase() === "ETC" && !title) ||
              !selectedDate ||
              !startAt ||
              !endAt ||
              (scheduleType.toUpperCase() === "PT" &&
                (!searchUser || !searchUser.tickets.length))
            }
          >
            등록하기
          </Button>
        ) : (
          <Dialog.Root variant="page">
            <Dialog.Trigger asChild>
              <Button
                variant="primary"
                styles={createButtonCSS}
                disabled={
                  (scheduleType.toUpperCase() === "ETC" && !title) ||
                  !selectedDate ||
                  !startAt ||
                  !endAt ||
                  (scheduleType.toUpperCase() === "PT" &&
                    (!searchUser || !searchUser.tickets.length))
                }
              >
                등록하기
              </Button>
            </Dialog.Trigger>
            <Dialog.Content styles={createButtonDialogContentCSS}>
              <Dialog.Header styles={createButtonDialogHeaderCSS}>
                <img src={infoIcon} css={infoIconCSS} />
                <div>
                  <Dialog.Title>설정한 시간을 확인해주세요</Dialog.Title>
                  <Dialog.Description>
                    종료 시간이 시작 시간을 앞설 수 없어요
                  </Dialog.Description>
                </div>
              </Dialog.Header>
              <Dialog.Body />
              <Dialog.Footer>
                <Dialog.Close variant="primary">확인</Dialog.Close>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog.Root>
        )}
      </div>
      <DialogPopup
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        title="기존 스케줄과 겹치는 시간이에요"
        body="날짜 및 시간을 다시 확인해주세요"
      />
    </div>
  );
}

const calendarIconCSS = css`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: ${colors.black};
`;

const datePickerInputContainerCSS = css`
  display: flex;
  width: 100%;
  height: 46px;
  padding: 11px 10px;
  align-items: center;
  align-self: stretch;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background: ${colors.gray25};

  .react-datepicker__input-container {
    width: 100%;
  }
`;

const datePickerInputCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.body2};
  border: none;
`;

const containerCSS = css`
  position: relative;
  width: 280px;
  flex-shrink: 0;
  border-left: 1px solid #e9eff2;
  background: ${colors.gray25};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  height: 100%;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  // 하단 등록하기 높이 68px + 여유값
  padding: 24px 24px 100px 24px;
`;

const closeButtonContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const infoContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .select-search-bar {
    width: 100%;
    color: ${colors.gray900};
    ${typography.mobile.body2};
  }

  .select__value-container {
    height: 100%;
  }

  .select__indicators {
    display: none;
  }

  .select__control {
    display: flex;
    height: 46px;
    align-items: center;
    align-self: stretch;
    gap: 2px;
    border-radius: 8px;
    border: 1px solid ${colors.default.Gray600};
    background: ${colors.gray25};
  }

  .select__value-container {
    height: 46px;
    padding: 0 10px;
  }

  .select__menu {
    display: flex;
    /* padding: 1px 12px; */
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    border-radius: 10px;
    border: 1px solid ${colors.gray100};
    background: ${colors.gray25};
    box-shadow: 0px 5px 10px 0px rgba(16, 16, 16, 0.2);
    margin-top: 2px;
    margin-bottom: 0;
  }

  .select__menu-list {
    width: 100%;
    padding: 0;
    border-radius: 10px;
  }

  .select__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: ${colors.gray25};
    color: ${colors.gray900};

    :hover {
      background-color: ${colors.gray100};
    }
  }

  .select__option--is-selected {
    background-color: ${colors.gray100};
    font-weight: 600;
  }
`;

const infoTitleCSS = css`
  align-self: stretch;
  color: ${colors.gray900};
  ${typography.mobile.body2};
  font-weight: ${fontWeight.SemiBold};
`;

const rowInfoContainerCSS = css`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .MuiOutlinedInput-root {
    display: flex;
    height: 46px;
    padding: 12px 10px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${colors.gray100};
    background: ${colors.gray25};
    ${typography.mobile.body2}
  }

  .MuiOutlinedInput-input {
    padding: 0;
  }
`;

const textareaCSS = css`
  height: 46px;
  justify-content: center;
  overflow: scroll;
`;

const memoTextareaCSS = css`
  height: 92px;
  justify-content: flex-start;
  overflow: scroll;
`;

const traineeTicketBoxContainerCSS = css`
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
`;

const traineeTicketBoxLeftArrowContainerCSS = css`
  position: absolute;
  display: flex;
  z-index: 1;
  left: -20px;
  width: 20px;
  height: 20px;
  padding: 4.5px 4px 3.5px 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${colors.White};
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
`;

const traineeTicketBoxRightArrowContainerCSS = css`
  position: absolute;
  display: flex;
  z-index: 1;
  right: -24px;
  width: 20px;
  height: 20px;
  padding: 4.5px 4px 3.5px 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${colors.White};
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
`;

const traineeTicketBoxArrowIconCSS = css`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  color: ${colors.black};
`;

const traineeTicketCarouselCSS = css`
  position: absolute;
  display: flex;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

const traineeTicketBoxCSS = css`
  display: flex;
  width: 232px;
  height: 80px;
  padding: 10.755px 15.364px;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(302deg, #b5f42e -23.35%, #e9ff6e 57.13%), #fff;
  border-radius: 8px;
  // TODO: 그림자 css 적용 @sello
  /* box-shadow: 0px 0px 7.682px 3.073px rgba(0, 0, 0, 0.05); */
`;

const traineeTicketCSS = css`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const traineeTicketActivityBadgeCSS = css`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 3.073px 4.609px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: ${colors.black};
  color: ${colors.gray25};
  ${typography.mobile.body2};
`;

const traineeTickeDescContainerCSS = css`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const traineeTicketDescCountTextCSS = css`
  color: ${colors.black};
  ${typography.mobile.body1};
`;

const traineeTicketDescDateTextCSS = css`
  color: ${colors.black};
  ${typography.mobile.body3};
`;

const traineeTicketPlaceHolderBoxCSS = css`
  display: flex;
  width: 232px;
  height: 80px;
  padding: 14px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${colors.gray50};
`;

const traineeTicketIconContainerCSS = css`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${colors.gray100};
  align-items: center;
  justify-content: center;
`;

const traineeTicketIconCSS = css`
  width: 18.286px;
  height: 18.286px;
  flex-shrink: 0;
  filter: invert(66%) sepia(7%) saturate(0%) hue-rotate(149deg) brightness(91%)
    contrast(88%);
`;

const traineeTicketPlaceHolderDescCSS = css`
  color: ${colors.gray400};
  ${typography.mobile.body3};
`;

const buttonContainerCSS = css`
  position: fixed;
  bottom: 0;
  display: flex;
  width: inherit;
  height: 68px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  flex-shrink: 0;
  border: 1px solid ${colors.gray50};
  background: ${colors.lightGray};
`;

const createButtonCSS = css`
  height: 40px;
  padding: 13.5px 16px;
  flex-shrink: 0;
`;

const createButtonDialogContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const createButtonDialogHeaderCSS = css`
  align-items: center;
  gap: 16px;
`;

const infoIconCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;
