// 2012-07-11 형태로 입력
export function isValidDate(dateString: string) {
  // 날짜 형식 검사: "YY-MM-DD"
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  console.log("kkkk");
  if (!regex.test(dateString)) return false;

  // 날짜 값을 분리
  const [year, month, day] = dateString.split("-").map(Number);

  // 월, 일 범위 검사  console.log(date);
  console.log(year);
  console.log(month);
  console.log(day);

  if (month < 1 || month > 12) return false; // 유효하지 않은 월
  if (day < 1 || day > 31) return false; // 유효하지 않은 일

  // 날짜 생성 및 검증
  const fullYear = year < 50 ? 2000 + year : 1900 + year; // YY를 4자리 연도로 변환
  const date = new Date(fullYear, month - 1, day);

  // 생성된 날짜와 비교하여 정확성 확인
  return (
    date.getFullYear() === fullYear &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

export const getKoreanWeekday = (dateString: string): string => {
  // Date 객체 생성
  const date = new Date(dateString);

  // 한국 요일 배열
  const weekdays = ["일", "월", "화", "수", "목", "금", "토"];

  // 요일 반환
  return weekdays[date.getDay()];
};
