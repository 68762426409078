import * as amplitude from "@amplitude/analytics-browser";

export function useAmplitude() {
  const init = () => {
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (apiKey) {
      amplitude.init(apiKey);
    }
  };

  const setUserId = (email: string) => {
    amplitude.setUserId(email);
  };

  const identifyUser = (userInfo: TrainerInfo) => {
    const identify = new amplitude.Identify();
    identify.set("name", userInfo.name);
    identify.set("birthdate", userInfo.birthDate);
    identify.set("phone", userInfo.phone);
    identify.set("is_male", userInfo.gender == "MALE");
    identify.set("email", userInfo.email);
    identify.set("year_of_experience", userInfo.yearsOfExperience);
    identify.set("is_verified", userInfo.isVerified);
    amplitude.identify(identify);
  };
  const sendClickEvent = ({
    eventName,
    screenName,
    eventProperties = {},
  }: sendEventProps) => {
    const updatedEventProperties = {
      ...eventProperties,
      type: EventType.event_status,
      screen_name: screenName,
    };

    const event = {
      event_type: eventName,
      event_properties: updatedEventProperties,
    };
    amplitude.track(event);
  };
  const sendEventStatus = ({
    eventName,
    screenName,
    eventProperties = {},
  }: sendEventProps) => {
    const updatedEventProperties = {
      ...eventProperties,
      type: EventType.event_status,
      screen_name: screenName,
    };

    const event = {
      event_type: eventName,
      event_properties: updatedEventProperties,
    };
    amplitude.track(event);
  };

  const sendScreenViewEvent = ({
    eventName,
    screenName,
    eventProperties = {},
  }: sendEventProps) => {
    const updatedEventProperties = {
      ...eventProperties,
      type: EventType.event_status,
      screen_name: screenName,
    };

    const event = {
      event_type: eventName,
      event_properties: updatedEventProperties,
    };

    amplitude.track(event);
  };

  return {
    init,
    identifyUser,
    setUserId,
    sendClickEvent,
    sendEventStatus,
    sendScreenViewEvent,
  };
}

type sendEventProps = {
  eventName: EventName;
  screenName: ScreenName;
  eventProperties?: { [key: string]: string };
};

export enum EventName {
  web_home_main_screen_viewed = "web_home_main_screen_viewed",
  web_crm_home_main_screen_viewed = "web_crm_home_main_screen_viewed",
  web_login_button_clicked = "web_login_button_clicked",
  web_search_trainer_button_clicked = "web_search_trainer_button_clicked",
  web_kakao_login_button_clicked = "web_kakao_login_button_clicked",
  web_start_crm_button_clicked = "web_start_crm_button_clicked",
  web_logout_button_clicked = "web_logout_button_clicked",
  web_report_item_button_clicked = "web_report_item_button_clicked",
  web_send_pt_diary_button_clicked = "web_send_pt_diary_button_clicked",
  web_send_pt_diary_successed = "web_send_pt_diary_successed",
  web_temporary_save_pt_diary_button_clicked = "web_temporary_save_pt_diary_button_clicked",
  web_copy_share_pt_diary_text_button_clicked = "web_copy_share_pt_diary_text_button_clicked",
  web_add_workout_button_clicked = "add_workout_button_clicked",

  web_onboarding_main_screen_viewed = "web_onboarding_main_screen_viewed",
  web_verify_phone_button_clicked = "web_verify_phone_button_clicked",
  web_onboarding_complete_button_clicked = "web_onboarding_complete_button_clicked",
  web_home_contents_description_clicked = "web_home_contents_description_clicked",

  web_search_trainer_detail_clicked = "web_search_trainer_detail_clicked",
}

export enum EventType {
  screen_viewed = "screen_viewed",
  screen_left = "screen_left",
  action = "action",
  event_status = "event_status",
}

export enum ScreenName {
  web_crm_home_main = "web_crm_home_main",
  web_home_main = "web_home_main",
  web_search_trainer_main = "web_search_trainer_main",
  web_search_trainer_detail = "web_search_trainer_detail",
  web_pt_report_list = "web_pt_report_list",
  web_pt_report_write = "web_pt_report_write",
  web_pt_report_detail = "web_pt_report_detail",
  web_onboarding_main = "web_onboarding_main",
}

export interface TrainerInfo {
  name: string;
  birthDate: string;
  phone: string;
  gender: string;
  email: string;
  yearsOfExperience: string;
  isVerified: boolean;
}
