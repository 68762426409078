import { BestPTReportDTO } from "./BestPracticesDTO";
import { piggyTrainer } from "./routuneDummy";
import image1 from ".//piggy/best1.png";
import image2 from ".//piggy/best2.png";
import image3 from ".//piggy/best3.png";
import image4 from ".//piggy/best4.png";
import image5 from ".//piggy/best5.png";
import image6 from ".//piggy/6.png";

export const bestPTReportList: BestPTReportDTO[] = [
  {
    trainer: piggyTrainer,
    reportList: [image1, image2, image3, image4, image5, image6],
    description: "",
  },
];
