import {
  QueryClient,
  QueryClientConfig,
  QueryKey,
  QueryObserverOptions,
  Updater,
} from "@tanstack/react-query";
import { GetSigninKakaoOauthTokenRequest } from "../api/trainer/signin/siginTypes";
import { GetLessonsListRequest } from "../api/trainer/lessons/lessonsTypes";
import {
  GetUsersUserIdLessonsLessonIdRequest,
  GetUsersUserIdWorkoutsWorkoutIdRequest,
} from "../api/trainer/users/usersTypes";
import {
  GetSchedulesRequest,
  GetSchedulesScheduleSlotIdRequest,
  GetSchedulesScheduleSlotIdUsersUserIdTicketRequest,
} from "../api/trainer/schedules/schedulesTypes";
import { getSchedulesScheduleSlotIdUsersUserIdTicket } from "../api/trainer/schedules/schedules";
import {
  getTrainersLessonListLessonId,
  getTrainersTrainerId,
} from "../api/admin/admin";
import { GetWorkoutsWorkoutIdRequest } from "api/trainer/workouts/workoutsTypes";
import { GetProfilesIdDetailRequest } from "api/trainer/profiles/profilesTypes";
import { GetListRequest } from "api/trainer/list/listTypes";

export const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
      onError: (err) => {
        throw err;
      },
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
      staleTime: 30 * 1000,
      networkMode: "always",
    },
    mutations: {
      retry: false,
      useErrorBoundary: true,
    },
  },
};

export const queryClient = new QueryClient(queryClientOptions);

export const queryKeys = {
  getSigninKakaoOauthToken: ({ code }: GetSigninKakaoOauthTokenRequest) => [
    "getSigninKakaoOauthToken",
    code,
  ],
  getLessonsList: ({ filter }: GetLessonsListRequest) => [
    "getLessonsList",
    filter,
  ],
  getUsersUserIdLessonsLessonId: ({
    userId,
    lessonId,
  }: GetUsersUserIdLessonsLessonIdRequest) => [
    "getUsersUserIdLessonsLessonId",
    userId,
    lessonId,
  ],
  getUsersUserIdWorkoutsWorkoutId: ({
    userId,
    workoutId,
    isCustom,
  }: GetUsersUserIdWorkoutsWorkoutIdRequest) => [
    "getUsersUserIdWorkoutsWorkoutId",
    userId,
    workoutId,
    isCustom,
  ],
  getUsersUserId: (userId: number) => ["getUsersUserId", userId],
  getWorkouts: () => ["getWorkouts"],
  getWorkoutsWorkoutId: ({ workoutId }: GetWorkoutsWorkoutIdRequest) => [
    "getWorkoutsWorkoutId",
    workoutId,
  ],
  getMe: () => ["getMe"],
  getList: ({ address }: GetListRequest) => ["getList", address],
  getProfilesIdDetail: ({ trainerId, address }: GetProfilesIdDetailRequest) => [
    "getProfilesIdDetail",
    trainerId,
    address,
  ],
  getTrainers: () => ["getTrainers"],
  getTrainee: () => ["getTrainee"],
  getTrainersLessonList: () => ["getTrainersLessonList"],
  getSchedules: ({ year, month }: GetSchedulesRequest) => [
    "getSchedules",
    year,
    month,
  ],
  getSchedulesScheduleSlotId: ({
    scheduleSlotId,
  }: GetSchedulesScheduleSlotIdRequest) => [
    "getSchedulesScheduleSlotId",
    scheduleSlotId,
  ],
  getSchedulesScheduleSlotIdUsersUserIdTicket: ({
    userId,
    scheduleSlotId,
  }: GetSchedulesScheduleSlotIdUsersUserIdTicketRequest) => [
    "getSchedulesScheduleSlotIdUsersUserIdTicket",
    userId,
    scheduleSlotId,
  ],
  getUsers: () => ["getUsers"],
  getUsersNewSearch: (email: string) => ["getUsersNewSearch", email],
  getTrainersLessonListLessonId: (id: number) => [
    "getTrainersLessonListLessonId",
    id,
  ],
  getTrainersTrainerId: (id: number) => ["getTrainersTrainerId", id],
  setError: (props: { key: string }) => ["setError", { ...props }],
} as const;

export const setQueryData = <T>({
  queryKey,
  options,
  updater,
}: {
  queryKey: QueryKey;
  options: QueryObserverOptions;
  updater: Updater<T | undefined, T | undefined>;
}) => {
  queryClient.setQueryDefaults(queryKey, options);
  queryClient.setQueryData(queryKey, updater);
};

export const resetErrorQueries = () => {
  const queryCache = queryClient.getQueryCache();
  const queryCaches = queryCache.getAll();

  const errorQueries = queryCaches
    .filter(({ state }) => {
      return state.status !== "success";
    })
    .reduce((acc: unknown[], { queryKey }) => {
      return [...acc, ...queryKey];
    }, []);

  queryClient.resetQueries(errorQueries, { exact: true });
};

export const clearQueries = () => {
  queryClient.clear();
};
