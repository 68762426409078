import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";
import { ImageView } from "design-system/components/ImageView/ImageView";
import avatarImage from "design-system/components/atom/IconResource/avatar_image.svg";
import {
  centerDistanceWithUnit,
  TrainerDetailDTO,
} from "../../api/admin/adminTypes";
import React from "react";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { getWidthMediaQuery } from "../../styles/mediaQueries";
import { useNavigate } from "react-router";
import { typography } from "design-system/styles/typography/typography";

interface TrainerInfoThumbnailProps {
  trainer: TrainerDetailDTO;
  distance: number | undefined;
}

export function TrainerInfoThumbnail({
  trainer,
  distance,
}: TrainerInfoThumbnailProps) {
  const { sendClickEvent } = useAmplitude();
  const navigate = useNavigate();

  return (
    <button
      key={trainer.id}
      css={trainerItemButtonCSS}
      onClick={() => {
        sendClickEvent({
          eventName: EventName.web_search_trainer_detail_clicked,
          screenName: ScreenName.web_home_main,
        });
        navigate(`/trainer/${trainer.id}?dist=${distance}`);
      }}
    >
      <>
        <div css={imageWrapperCSS}>
          <ImageView
            src={trainer.mainImage?.url ?? avatarImage}
            styles={trainerImageCSS}
            isClickable={false}
          />
        </div>
        <div css={trainerItemInfoContainerCSS}>
          <div css={nameContainerCSS}>
            <div css={trainerNameTextCSS}>{`${trainer.name}`}</div>
            {trainer.profilePercent > 70 && (
              <div css={recommendBadgeCSS}>{"추천"}</div>
            )}
          </div>
          <div css={centerCSS}>
            <span>{trainer.centerInfo?.name}</span>
            {distance && (
              <span>{` | ${centerDistanceWithUnit(distance)}`}</span>
            )}
          </div>
        </div>
      </>
    </button>
  );
}

const trainerNameTextCSS = css`
  ${typography.mobile.heading2};
  white-space: nowrap; /* 줄바꿈 방지 */
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis; /* 말줄임 (...) 처리 */
`;

const centerCSS = css`
  ${typography.mobile.body2};
`;

const nameContainerCSS = css`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  ${typography.mobile.heading2};
  justify-content: space-between;
`;

const recommendBadgeCSS = css`
  display: flex;
  //padding: 3px 5px;
  justify-content: center;
  width: 44px;
  height: 30px;
  align-items: center;
  border-radius: 2px;
  background: ${colors.gray25};
  color: ${colors.gray900};
  ${typography.mobile.body3};
`;

const trainerImageCSS = css`
  width: 100%;
  height: 160px;

  ${getWidthMediaQuery("pc")} {
    height: 195px;
    border-radius: 8px 8px 0 0;
  }
`;

const trainerItemInfoContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 10px 10px 10px;
  width: 100%;
  gap: 10px;
  text-align: start;

  ${getWidthMediaQuery("pc")} {
    padding: 17.5px 16px;
  }
`;

const imageWrapperCSS = css`
  width: 100%;
`;

const trainerItemButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: ${colors.gray25};
  border-radius: 8px;
  background: #22251e;
  padding: 0;

  width: 160px;

  ${getWidthMediaQuery("pc")} {
    width: 255px;
    height: 310px;
  }
`;
