import { css } from "@emotion/react";
import {
  fontFamily,
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import React, { useState } from "react";
import { LoginDialog } from "../login-dialog/LoginDialog";
import LoginDialogContext from "../login-dialog/loginDialogContext";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";
import { getWidthMediaQuery } from "../../styles/mediaQueries";
import { colors } from "design-system/styles/colors";
import Button from "design-system/components/Button/Button";
import { useNavigate } from "react-router";
import { useWindowSize } from "../../hoock/useWindowSize";
import IcMenu from "design-system/components/atom/IconResource/Icon/IcMenu";
import { Sidebar } from "design-system/components/Sidebar/Sidebar";

export function MainHeader() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { sendClickEvent } = useAmplitude();
  const { isMobile } = useWindowSize();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div css={containerCSS}>
      {!isMobile && (
        <div css={titleContainerCSS}>
          <Button variant="text" css={headerCSS} onClick={() => navigate("/")}>
            FitsYou
          </Button>
          <div css={menuContainerCSS}>
            <Button
              variant="text"
              styles={titleMenuButtonCSS}
              onClick={() => {
                sendClickEvent({
                  eventName: EventName.web_search_trainer_button_clicked,
                  screenName: ScreenName.web_home_main,
                });
                navigate("/trainer/list");
              }}
            >
              {"트레이너 찾기"}
            </Button>
            <Button
              variant="text"
              styles={titleMenuButtonCSS}
              onClick={() => {
                sendClickEvent({
                  eventName: EventName.web_search_trainer_button_clicked,
                  screenName: ScreenName.web_home_main,
                });
                navigate("/trainer/manage");
              }}
            >
              {"PT 관리"}
            </Button>
          </div>
          <div css={loginButtonContainer}>
            <span
              css={loginButtonTextCSS}
              onClick={() => {
                sendClickEvent({
                  eventName: EventName.web_login_button_clicked,
                  screenName: ScreenName.web_home_main,
                });
                setIsOpen(!isOpen);
              }}
            >
              로그인/회원 가입
            </span>
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          <div css={titleContainerCSS}>
            <Button
              variant="text"
              css={mobileHeaderCSS}
              onClick={() => navigate("/")}
            >
              FitsYou
            </Button>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div css={loginButtonContainer}>
                <span
                  css={loginButtonTextCSS}
                  onClick={() => {
                    sendClickEvent({
                      eventName: EventName.web_login_button_clicked,
                      screenName: ScreenName.web_home_main,
                    });
                    setIsOpen(!isOpen);
                  }}
                >
                  로그인/회원 가입
                </span>
              </div>
              <Button
                variant="icon"
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen);
                }}
              >
                <IcMenu
                  css={css`
                    color: ${colors.White};
                  `}
                />
              </Button>
            </div>
          </div>
        </>
      )}
      <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
        <LoginDialog />
      </LoginDialogContext.Provider>
    </div>
  );
}

const menuContainerCSS = css`
  display: flex;
  gap: 20px;
  width: 100%;
  padding-left: 10px;

  ${getWidthMediaQuery("pc")} {
    padding-left: 0;
  }
`;

const loginButtonTextCSS = css`
  color: ${colors.gray25};
  ${typography.mobile.body1};

  ${getWidthMediaQuery("pc")} {
    ${typography.Body4};
    color: ${colors.lime600};
  }
`;

const loginButtonContainer = css`
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  width: 100%;
  margin-left: auto;
  justify-content: flex-end;
  padding-right: 20px;

  ${getWidthMediaQuery("pc")} {
    min-width: 140px;
  }
`;

const containerCSS = css`
  z-index: 3;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);
  padding: 19px 16px;

  ${getWidthMediaQuery("pc")} {
    padding: 40px 180px;
  }
`;

const headerCSS = css`
  color: #fff;
  font-size: 30px;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-weight: ${fontWeight.Bold};
  min-width: 130px;
`;

const mobileHeaderCSS = css`
  color: #fff;
  font-size: 20px;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-weight: ${fontWeight.Bold};
  text-align: start;
`;

const titleContainerCSS = css`
  display: flex;
  align-items: baseline;
  gap: 6px;
  width: 100%;
  justify-content: space-between;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    gap: 60px;
    justify-content: initial;
  }
`;

const titleMenuButtonCSS = css`
  color: ${colors.gray25};
  ${typography.mobile.body1};

  ${getWidthMediaQuery("pc")} {
    ${typography.Body4};
  }
`;
