import { customFetch } from "../../../libs/fetch/fetch";
import {
  GetProfilesIdDetailRequest,
  GetProfilesIdDetailResponse,
} from "./profilesTypes";

export async function getProfilesIdDetail({
  trainerId,
  address,
}: GetProfilesIdDetailRequest): Promise<GetProfilesIdDetailResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/profiles/${trainerId}/detail`,
    params: { address },
  });
}
