import { css } from "@emotion/react";
import { useSearchParams } from "react-router-dom";
import { colors } from "design-system/styles/colors";
import { useState } from "react";
import { CrmHeader } from "../../../common/Header/CrmHeader";
import { Sidebar } from "../../component/Sidebar/Sidebar";

import { ReportType } from "../../../api/common/commonEnumType";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUsersUserIdLessonsLessonId } from "../../../api/trainer/users/users";
import { queryClient, queryKeys } from "../../../libs/react-query";
import CreateContext from "./createContext";
import {
  MixedWorkoutDetail,
  RequestSaveLessonRequestDTO,
  workoutDetailTORequestSaveLessonRequestWorkoutDetailDTO,
} from "../../../api/trainer/schedules/schedulesTypes";
import {
  postSchedulesScheduleSlotIdLessons,
  putSchedulesScheduleSlotIdLessons,
} from "../../../api/trainer/schedules/schedules";
import { useNavigate } from "react-router";
import Loading from "design-system/components/Loading/Loading";
import { SendErrorDialog } from "./component/SendErrorDialog/SendErrorDialog";
import { ReportShareDialog } from "../component/ReportShareDialog/ReportShareDialog";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../hoock/useAmplitude";
import { ReportWriteTemplate } from "./component/ReportWriteTemplete/ReportWriteTemplate";
import {
  AttachFileDTO,
  PostUploadsPresignedRequest,
} from "../../../api/common/commonTypes";
import { postUploadsPresigned } from "../../../api/common/common";
import { customFetch, CustomFetchError } from "../../../libs/fetch/fetch";
import { extractVideoThumbnailFile } from "../../../hoock/useUrlToFile";

export function CrmReportWritePage() {
  const [searchParams] = useSearchParams();
  const [lessonId, setLessonId] = useState(
    parseInt(searchParams.get("lessonId") ?? "0", 10),
  );
  const userId = parseInt(searchParams.get("userId") ?? "0", 10);
  const ticketId = parseInt(searchParams.get("ticketId") ?? "0", 10);
  const signImageUrl =
    searchParams.get("signImage") === "null" ||
    searchParams.get("signImage") == null
      ? undefined
      : searchParams.get("signImage") ?? undefined;

  const [isNoSetError, setIsNoSetError] = useState(false);
  const [isNoWorkoutError, setIsNoWorkoutError] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isRequireDataMissingError, setIsRequireDataMissingError] =
    useState(false);
  const [isFutureReportError, setIsFutureReportError] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const { sendClickEvent } = useAmplitude();
  const preferredType =
    ((searchParams.get("type") ||
      "feedback_workout_set_weight") as ReportType) ||
    "feedback_workout_set_weight";

  const scheduleSlotId = parseInt(
    searchParams.get("scheduleSlotId") || "0",
    10,
  );
  const navigate = useNavigate();

  const { data: lessonData } = useQuery({
    queryFn: () =>
      getUsersUserIdLessonsLessonId({
        lessonId,
        userId,
      }),
    queryKey: queryKeys.getUsersUserIdLessonsLessonId({
      lessonId,
      userId,
    }),
    enabled: lessonId !== 0,
  });

  const [reportData, setReportData] = useState<
    RequestSaveLessonRequestDTO & {
      mixedWorkoutDetail?: MixedWorkoutDetail[];
    }
  >({
    userId,
    ticketId,
    lessonResultType:
      lessonData?.lessonResultType ||
      (preferredType ? preferredType : "feedback_workout_set_weight"),
    workoutTime: lessonData?.workoutSummary?.totalTime ?? 50,
    totalFeedback: lessonData?.totalFeedback ?? "",
    mixedWorkoutDetail: lessonData?.workoutDetails.map(
      (detail) =>
        workoutDetailTORequestSaveLessonRequestWorkoutDetailDTO(detail) ?? [],
    ),
    workoutDetails: [],
  });

  const { mutate: sendReportMutate, isLoading: isSending } = useMutation({
    mutationFn: postSchedulesScheduleSlotIdLessons,
    onSuccess: async ({ lessonId: responseLessonId }) => {
      queryClient.invalidateQueries(queryKeys.getLessonsList({}));
      queryClient.invalidateQueries(
        queryKeys.getSchedulesScheduleSlotId({ scheduleSlotId }),
      );
      sendLog(EventName.web_send_pt_diary_successed);
      // `lessonData`를 다시 가져오기
      setLessonId(responseLessonId);
      setIsShareDialogOpen(true);
    },
    onError: (error: CustomFetchError) => {
      if (error.data?.code === 400122) {
        setIsFutureReportError(true);
      }
    },
    useErrorBoundary: (error: CustomFetchError) => error.data?.code !== 400122,
  });

  const { mutate: saveTempReportMutate, isLoading: isSaving } = useMutation({
    mutationFn: putSchedulesScheduleSlotIdLessons,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getLessonsList({}));
      queryClient.invalidateQueries(
        queryKeys.getUsersUserIdLessonsLessonId({ userId, lessonId }),
      );
      queryClient.invalidateQueries(
        queryKeys.getSchedulesScheduleSlotId({ scheduleSlotId }),
      );
      navigate("/crm");
    },
  });

  const sendLog = (eventName: EventName) => {
    sendClickEvent({
      eventName: eventName,
      screenName: ScreenName.web_pt_report_write,
      eventProperties: {
        feedback_type: reportData.lessonResultType,
        image_feedback_count:
          reportData.mixedWorkoutDetail?.reduce(
            (acc, item) => {
              const imageCount = item?.imagesDetail?.length ?? 0;
              return (parseInt(acc) + imageCount).toString();
            },
            "0", // 초기값을 문자열로 설정
          ) || "0",
        video_feedback_count:
          reportData.mixedWorkoutDetail?.reduce(
            (acc, item) => {
              const videoCount = item?.videosDetail?.length ?? 0;
              return (parseInt(acc) + videoCount).toString();
            },
            "0", // 초기값을 문자열로 설정
          ) || "0",
        is_prefer_feedback_matched:
          reportData.lessonResultType == lessonData?.lessonResultType
            ? "true"
            : "false",
      },
    });
  };

  const { mutateAsync: preSigned } = useMutation<
    AttachFileDTO,
    Error,
    PostUploadsPresignedRequest
  >({
    mutationFn: postUploadsPresigned,
  });

  const resourceUpload = async () => {
    for (const workoutsDetail of reportData.mixedWorkoutDetail || []) {
      for (const image of workoutsDetail.imagesDetail) {
        if (!image.id.includes("placeholder-")) continue;

        try {
          const response: AttachFileDTO = await preSigned({
            type: "user_workout_image",
            contentType: "png",
          });

          await customFetch(
            {
              app: "common",
              version: "v1",
              path: `/uploads/presigned`,
            },
            {
              method: "PUT",
              headers: {
                "Content-Type": "image/png",
              },
              body: image.file, // 파일 객체를 body로 직접 전달
            },
            response.url,
          );

          image.id = response.id;
        } catch (error) {
          console.error("Pre-signed URL 요청 실패:", error);
        }
      }

      for (const video of workoutsDetail.videosDetail) {
        if (!video.id.includes("placeholder-")) continue;

        try {
          const response = await preSigned({
            type: "user_workout_video",
            contentType: video.file?.type.includes("mp4") ? "mp4" : "mov",
            thumbnailContentType: "png",
          });

          await fetch(response.url, {
            method: "PUT",
            headers: {
              "Content-Type": video.file?.type ?? "video/mp4",
            },
            body: video.file, // 파일 객체를 body로 직접 전달
          });

          if (response.thumbnail && video.file) {
            const thumbnailFile = await extractVideoThumbnailFile(video.file);
            await fetch(response.thumbnail, {
              method: "PUT",
              headers: {
                "Content-Type": "image/png",
              },
              body: thumbnailFile, // 파일 객체를 body로 직접 전달
            });
          }
          video.id = response.id;
        } catch (error) {
          console.error("Pre-signed URL 요청 실패:", error);
        }
      }
    }
  };
  const getReportResponse = () => {
    reportData.mixedWorkoutDetail?.map((item) => console.log(item));

    if (reportData.lessonResultType == "feedback_only") {
      return {
        scheduleSlotId,
        ...reportData,
        workoutDetails: undefined,
      };
    } else if (reportData.lessonResultType == "feedback_workout") {
      return {
        scheduleSlotId,
        ...reportData,
        workoutDetails: reportData.mixedWorkoutDetail?.map((detail) => ({
          id: detail.id,
          isCustom: detail.isCustom,
          workoutName: detail.workoutName,
          part: detail.part,
          sets: [],
          images: detail.imagesDetail.map((image) => image.id), // imagesDetail의 url 변환
          videos: detail.videosDetail.map((video) => video.id), // videosDetail의 url 변환
          feedback: detail.feedback,
        })),
      };
    } else {
      return {
        scheduleSlotId,
        ...reportData,
        workoutDetails: reportData.mixedWorkoutDetail?.map((detail) => ({
          id: detail.id,
          isCustom: detail.isCustom,
          workoutName: detail.workoutName,
          part: detail.part,
          sets: detail.sets,
          images: detail.imagesDetail.map((image) => image.id), // imagesDetail의 url 변환
          videos: detail.videosDetail.map((video) => video.id), // videosDetail의 url 변환
          feedback: detail.feedback,
        })),
      };
    }
  };

  const saveTempReport = async () => {
    sendLog(EventName.web_temporary_save_pt_diary_button_clicked);
    setIsProcessing(true);
    await resourceUpload();
    saveTempReportMutate(getReportResponse());
    setIsProcessing(false);
  };

  const sendReport = async () => {
    sendLog(EventName.web_send_pt_diary_button_clicked);
    if (checkError()) {
      return;
    }
    setIsProcessing(true);
    await resourceUpload();
    sendReportMutate(getReportResponse());
    setIsProcessing(false);
  };

  const checkError = () => {
    if (
      reportData.totalFeedback?.length == 0 ||
      reportData.totalFeedback == undefined ||
      reportData.workoutTime == undefined
    ) {
      setIsRequireDataMissingError(true);
      return true;
    }

    if (reportData.lessonResultType !== "feedback_only") {
      if (
        !reportData.mixedWorkoutDetail ||
        reportData.mixedWorkoutDetail?.length == 0
      ) {
        setIsNoWorkoutError(true);
        return true;
      }
    }

    if (reportData.lessonResultType == "feedback_workout_set_weight") {
      const hasEmptySets = reportData.mixedWorkoutDetail?.some(
        (detail) => detail.sets.length === 0,
      );

      if (hasEmptySets) {
        setIsNoSetError(true);
        return true;
      }
    }

    return false;
  };

  return (
    <CreateContext.Provider
      value={{
        data: reportData,
        setData: setReportData,
        saveTempRetort: saveTempReport,
        sendTempRetort: sendReport,
        signImagUrl: signImageUrl,
      }}
    >
      {isProcessing && <Loading />}
      {!isProcessing && (
        <div css={containerCSS}>
          <CrmHeader />
          <div css={contentsContainerCSS}>
            <Sidebar />
            <ReportWriteTemplate />
          </div>
          <SendErrorDialog
            isNoSetError={isNoSetError}
            setIsNoSetError={setIsNoSetError}
            isNoWorkoutError={isNoWorkoutError}
            setIsNoWorkoutError={setIsNoWorkoutError}
            isRequireDataMissingError={isRequireDataMissingError}
            setIsRequireDataMissingError={setIsRequireDataMissingError}
            isFutureReportError={isFutureReportError}
            setIsFutureReportError={setIsFutureReportError}
          />

          {lessonData && (
            <ReportShareDialog
              isOpen={isShareDialogOpen}
              setIsOpen={(state) => {
                setIsShareDialogOpen(state);
              }}
              lessonData={lessonData}
              userId={userId}
              scheduleSlotId={scheduleSlotId}
              screenName={ScreenName.web_pt_report_write}
              onClose={() => navigate("crm/")}
            />
          )}
        </div>
      )}
    </CreateContext.Provider>
  );
}

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;
