import { useContext } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "libs/react-query";
import { getList } from "api/trainer/list/list";
import { centerDistanceWithUnit } from "api/admin/adminTypes";
import { EventName, ScreenName, useAmplitude } from "hoock/useAmplitude";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { ImageView } from "design-system/components/ImageView/ImageView";
import avatarImage from "design-system/components/atom/IconResource/avatar_image.svg";
import trainerSearchListContext from "../trainerSearchListContext";
import { getWidthMediaQuery } from "styles/mediaQueries";

export function TrainerList() {
  const navigate = useNavigate();
  const { address } = useContext(trainerSearchListContext);
  const { sendClickEvent } = useAmplitude();

  const { data: trainers } = useQuery({
    queryFn: () => getList({ address }),
    queryKey: queryKeys.getList({ address }),
  });

  return (
    <div css={listContainerCSS}>
      {trainers?.content.map(({ trainer, distance }) => (
        <button
          key={trainer.id}
          css={trainerItemButtonCSS}
          onClick={() => {
            sendClickEvent({
              eventName: EventName.web_search_trainer_detail_clicked,
              screenName: ScreenName.web_search_trainer_main,
            });
            navigate(`/trainer/${trainer.id}?dist=${distance}`);
          }}
        >
          <>
            <div css={imageWrapperCSS}>
              <ImageView
                src={trainer.mainImage?.url ?? avatarImage}
                styles={trainerImageCSS}
                isClickable={false}
              />
            </div>
            <div css={trainerItemInfoContainerCSS}>
              <div css={nameContainerCSS}>
                {`${trainer.name} 트레이너`}
                {trainer.profilePercent > 70 && (
                  <div css={recommendBadgeCSS}>{"추천"}</div>
                )}
              </div>
              <div css={centerCSS}>
                <span>{trainer.centerInfo?.name}</span>
                {distance && (
                  <span>{` | ${centerDistanceWithUnit(distance)}`}</span>
                )}
              </div>
            </div>
          </>
        </button>
      ))}
    </div>
  );
}

const listContainerCSS = css`
  display: grid;
  justify-content: center;
  grid-gap: 16px 8px;
  grid-template-columns: repeat(auto-fit, 160px);
  padding: 0 16px;

  ${getWidthMediaQuery("pc")} {
    align-items: flex-start;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, 255px);
    padding: 0;
  }
`;

const trainerItemButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${colors.gray25};
  border-radius: 8px;
  background: #22251e;
  padding: 0;
  width: 160px;
  height: 195px;

  ${getWidthMediaQuery("pc")} {
    width: 255px;
    height: 310px;
  }
`;

const imageWrapperCSS = css`
  width: 100%;
`;

const trainerImageCSS = css`
  width: 100%;
  height: 123px;
  border-radius: 8px 8px 0 0;

  ${getWidthMediaQuery("pc")} {
    height: 195px;
  }
`;

const trainerItemInfoContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17.5px 16px;
  gap: 10px;
  text-align: start;
`;

const nameContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
  ${typography.mobile.body2};

  ${getWidthMediaQuery("pc")} {
    ${typography.mobile.heading2};
  }
`;

const recommendBadgeCSS = css`
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 3.5px 5.5px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: ${colors.gray25};
  color: ${colors.gray900};
  ${typography.mobile.body4};

  ${getWidthMediaQuery("pc")} {
    ${typography.mobile.body3};
  }
`;

const centerCSS = css`
  ${typography.mobile.body4};

  ${getWidthMediaQuery("pc")} {
    ${typography.mobile.body2};
  }
`;
