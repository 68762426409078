import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";
import lowerBG from "resource/trainer/homeResource/homepage_lower.png";
import { Frame4 } from "../../trainee/Frame4/Frame4";
import Frame5 from "../../trainee/frame5/Fram5";
import Button from "design-system/components/Button/Button";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";
import { customFetch } from "../../libs/fetch/fetch";
import { getWidthMediaQuery } from "../../styles/mediaQueries";
import appleLogo from "resource/trainer/homeResource/apple_logo.png";
import desktopLogo from "resource/trainer/homeResource/desktop_emoji.png";

import playStoreLogo from "resource/trainer/homeResource/play_store_logo.png";
import { MainHeader } from "../../common/Header/MainHeader";
import LoginDialogContext from "../../common/login-dialog/loginDialogContext";
import { LoginDialog } from "../../common/login-dialog/LoginDialog";
import { DescriptionFrame } from "../component/description/DescriptionFrame";
import { typography } from "design-system/styles/typography/typography";

export function TrainerMangePage() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const hasSentLog = useRef(false);
  const { sendClickEvent, sendScreenViewEvent } = useAmplitude();

  const [isOpen, setIsOpen] = useState(false);
  const trainerAppleStore =
    "https://apps.apple.com/us/app/핏츠유-트레이너/id6504490224";
  const trainerPlayStore =
    "https://play.google.com/store/apps/details?id=kr.co.fitsyou.app.trainer&hl=ko-KR";
  const userPlayStore =
    "https://play.google.com/store/apps/details?id=kr.co.fitsyou.app";
  const userAppleStore =
    "https://apps.apple.com/kr/app/%ED%95%8F%EC%B8%A0%EC%9C%A0/id6544784222";
  useEffect(() => {
    if (!hasSentLog.current) {
      sendScreenViewEvent({
        eventName: EventName.web_home_main_screen_viewed,
        screenName: ScreenName.web_home_main,
      });
    }
  });

  useEffect(() => {
    if (state == "logout") {
      customFetch(
        {
          app: "user",
          version: "v1",
          path: `/logout`,
        },
        { method: "POST" },
      );
    }
  }, [state]);

  const onsStartWebButtonClick = () => {
    sendClickEvent({
      eventName: EventName.web_start_crm_button_clicked,
      screenName: ScreenName.web_home_main,
    });
    setIsOpen(!isOpen);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        background: black;
        padding-bottom: 0;
        width: 100%;
        overflow: hidden;
      `}
    >
      <MainHeader />
      <div css={containerCSS}>
        <div css={frame1containerCSS}>
          <div css={emphasisTitleCSS}>
            PT 관리의 모든것, <br />
            핏츠유
          </div>
          <div css={buttonContainerCSS}>
            <div css={buttonItemContainerCSS}>
              <div css={downloadTextCSS}>회원</div>
              <div css={buttonInterItemContainerCSS}>
                <Button
                  css={buttonCSS}
                  onClick={() => window.open(userAppleStore, "_blank")}
                >
                  <img src={appleLogo} css={logoCSS} />
                  Apple Store
                </Button>
                <Button
                  css={buttonCSS}
                  onClick={() => window.open(userPlayStore, "_blank")}
                >
                  <img src={playStoreLogo} css={logoCSS} />
                  Google Play
                </Button>
              </div>
            </div>
            <div css={buttonItemContainerCSS}>
              <div css={downloadTextCSS}>트레이너</div>
              <div css={buttonInterItemContainerCSS}>
                <Button
                  css={buttonCSS}
                  onClick={() => window.open(trainerAppleStore, "_blank")}
                >
                  <img src={appleLogo} css={logoCSS} />
                  Apple Store
                </Button>
                <Button
                  css={buttonCSS}
                  onClick={() => window.open(trainerPlayStore, "_blank")}
                >
                  <img src={playStoreLogo} css={logoCSS} />
                  Google Play
                </Button>
                <Button css={buttonCSS} onClick={onsStartWebButtonClick}>
                  <img src={desktopLogo} css={logoCSS} />
                  Web
                </Button>
              </div>
            </div>
          </div>
          <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
            <LoginDialog />
          </LoginDialogContext.Provider>
        </div>
        <DescriptionFrame />
        <div css={BottomDecoContainerCSS}>
          <Frame4 />
          <Frame5 />
          <img
            src={lowerBG}
            css={css`
              object-fit: contain;
              bottom: 0;
              position: absolute;
              z-index: -1;
              width: 100%;
              max-height: 1300px;
            `}
          />
        </div>
      </div>
    </div>
  );
}

const downloadTextCSS = css`
  ${typography.mobile.heading2};
  color: white;
`;

const frame1containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  z-index: 2;
`;

const logoCSS = css`
  height: 22px;
  width: 22px;
`;

const buttonInterItemContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    gap: 17px;
  }
`;

const buttonItemContainerCSS = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    gap: 50px;
  }
`;

const buttonContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  z-index: 2;
  margin-top: 30px;

  ${getWidthMediaQuery("pc")} {
    gap: 50px;
    margin-top: 90px;
  }
`;

const buttonCSS = css`
  display: flex;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  color: white;
  padding: 12px 16px;
  font-size: 12px;

  ${getWidthMediaQuery("pc")} {
    font-size: 16px;
    padding: 16px 24px;
  }
`;

const emphasisTitleCSS = css`
  background: linear-gradient(109deg, #83f9e9 -2.12%, #e0f569 111.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 77px;
  font-size: 45px;

  ${getWidthMediaQuery("pc")} {
    font-size: 100px;
    margin-top: 137px;
  }
`;

const BottomDecoContainerCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  background-color: black;
`;
