import { TrainerMyInfoDTO } from "../trainer/me/meTypes";
import { LessonTrainerLessonSummaryDTO } from "../trainer/lessons/lessonsTypes";
import { LessonUserLessonDetailDTO } from "../trainer/users/usersTypes";
import {
  Gender,
  ReportType,
  YearsOfExperience,
} from "../common/commonEnumType";
import { AttachFileDTO } from "../common/commonTypes";

export type GetTrainersLessonsListResponse = ListWrapperTrainerLessonDTO;

export type ListWrapperTrainerLessonDTO = {
  content: AdminTrainerLessonDTO[];
};
export type AdminTrainerLessonDTO = {
  trainer: TrainerMyInfoDTO;
  lessons: LessonTrainerLessonSummaryDTO[];
};

export type GetTrainersLessonsLessonIdResponse = LessonUserLessonDetailDTO;

export type GetTrainersLessonListLessonIdRequest = {
  trainerId: string;
  userId: string;
  workoutId: string;
};

export type GetTrainersResponse = ListWrapperTrainerMyInfo;

export type ListWrapperTrainerMyInfo = {
  content: TrainerMyInfoDTO[];
};

export type GetTraineesResponse = ListWrapperMyInfo;

export type ListWrapperMyInfo = {
  content: MyInfoDTO[];
};

export interface MyInfoDTO {
  name?: string;
  birthdate?: string;
  phone?: string;
  gender?: Gender;
  email: string;
  lessonResultType: ReportType;
  isVerified: boolean;
}

export type CenterDTO = {
  name: string;
  tel: string;
  address: string;
  additionalInfo?: string;
};

export type TrainerDetailDTO = {
  id: number;
  name?: string;
  gender?: Gender;
  speciality: SpecialityType;
  mainImage?: AttachFileDTO;
  images: AttachFileDTO[];
  event?: string;
  selfIntroduction?: string;
  yearsOfExperience?: YearsOfExperience;
  trainerTypes: TrainerType[];
  workoutTags: WorkoutTagType[];
  qualifications: QualificationsDto[];
  careers: CareerDto[];
  prices: PriceDTO;
  centerInfo?: CenterDTO;
  profilePercent: number;
};

type SpecialityType = "health" | "pilates";

type TrainerType =
  | "one_time_trial"
  | "physical_therapist"
  | "five_years_or_more"
  | "related_major"
  | "master_degree_or_higher"
  | "female_coach";

type WorkoutTagType =
  | "improved_performance"
  | "guide_workout"
  | "body_profile"
  | "correction_pain_relief"
  | "muscle_fat_control"
  | "physical_strength"
  | "prepare_contest";

type QualificationsDto = {
  id: number;
  qualificationType: QualificationType;
  title?: string;
  degree?: DegreeType;
  university?: string;
  department?: string;
  isRelatedDepartment?: boolean;
  image?: string;
  verified: boolean;
};

type PriceDTO = {
  prices: PricePriceDTO[];
};

export type PricePriceDTO = {
  cycle: number;
  price: number;
  timeSpecial: TimeSpecial;
};

type TimeSpecial = {
  type: TimeSpecialType;
  startTime: LocalTime;
  endTime: LocalTime;
};

export type TimeSpecialType = "lunch_time" | "weekend" | "dead_of_night";

type LocalTime = {
  hour: number;
  minute: number;
  second: number;
  nano: number;
};

type CareerDto = {
  id: number;
  centerName: string;
  startDate: string;
  endDate: string;
};

type QualificationType = "certification" | "education" | "awards";

type DegreeType = "bachelors_degree" | "master" | "doctors_degree";

export type GetTrainersTrainerIdResponse = TrainerDetailDTO;

export const degreeTypeInKR = (degree: DegreeType) => {
  switch (degree) {
    case "bachelors_degree":
      return "학사";
    case "master":
      return "석사";
    case "doctors_degree":
      return "박사";
  }
};

export const centerDistanceWithUnit = (distance: number) => {
  if (distance < 1000) {
    return `${distance}m`;
  }
  return `${(distance / 1000).toFixed(1)}km`;
};

export const trainerTypeInKR = (type: TrainerType) => {
  switch (type) {
    case "one_time_trial":
      return "1회 무료 체험";
    case "physical_therapist":
      return "물리치료사 출신";
    case "five_years_or_more":
      return "5년차 이상";
    case "related_major":
      return "관련 전공자";
    case "master_degree_or_higher":
      return "석사 이상";
    case "female_coach":
      return "여성 코치";
  }
};

export const timeSpecialTypeInKR = (type: TimeSpecialType) => {
  switch (type) {
    case "lunch_time":
      return "점심시간";
    case "weekend":
      return "주말";
    case "dead_of_night":
      return "심야";
  }
};
