import { getUsers } from "../../api/trainer/users/users";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../libs/react-query";
import { CrmHeader } from "../../common/Header/CrmHeader";
import { Sidebar } from "../component/Sidebar/Sidebar";
import React, { useState } from "react";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../component/crmCSS";
import { typography } from "design-system/styles/typography/typography";
import { AddMemberByEmailDialog } from "./component/AddMemberByEmailDialog/AddMemberByEmailDialog";
import { DialogPopup } from "design-system/components/DialogPopup/DialogPopup";
import Button from "design-system/components/Button/Button";
import { UserInfoListItem } from "./component/UserInfoListItem/UserInfoListItem";
import { Textarea } from "design-system/components/Textarea/Textarea";
import IcPlus from "design-system/components/atom/IconResource/Icon/IcPlus";
import { useNavigate } from "react-router";

export function UserManagePage() {
  const [searchName, setSearchName] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEmailInputOpen, setIsEmailInputOpen] = useState(false);
  const [isEmailInputError, setIsEmailInputError] = useState(false);

  const navigate = useNavigate();

  const { data: users } = useQuery({
    queryFn: () => getUsers(),
    queryKey: queryKeys.getUsers(),
  });

  return (
    <div css={containerCSS}>
      <CrmHeader />
      <div css={contentsContainerCSS}>
        <Sidebar />
        <div css={contentContainerCSS}>
          <Textarea
            isAutoHeight={false}
            css={textAreaCSS}
            placeholder="찾으실 회원님의 성함을 입력해주세요"
            isShowReset={true}
            isPreventNewline={true}
            isPreventBlank={true}
            onChange={(event) => {
              const newValue = event.target.value;
              setSearchName(newValue);
            }}
          />
          <div css={listContainerCSS}>
            {users?.content
              ?.filter((item) => {
                // searchName이 빈 문자열이면 모든 항목을 통과
                if (searchName.trim() === "") return true;

                // 이름이 searchName과 일치하는 경우만 필터링
                return item.userInfo.name.match(searchName);
              })
              ?.map((user) =>
                user.tickets.map((ticket) => (
                  <div key={ticket.id} css={css``}>
                    <UserInfoListItem
                      userInfo={user.userInfo}
                      ticket={ticket}
                    />
                  </div>
                )),
              )}
          </div>
          <div css={addTicketButtonContainerCSS}>
            {isAddOpen && (
              <div css={addButtonContainerCSS}>
                <div css={addButtonItemContainerCSS}>
                  <div
                    css={[
                      addButtonCSS,
                      css`
                        background: ${colors.gray100};
                        border-radius: 10px 10px 0 0;
                      `,
                    ]}
                  >
                    수강생 앱 설치시
                  </div>
                  <Button
                    css={[
                      addButtonCSS,
                      css`
                        background: white;
                        border-radius: 10px;
                      `,
                    ]}
                    onClick={() => setIsEmailInputOpen(true)}
                  >
                    이메일로 추가
                  </Button>
                </div>
                <div css={addButtonItemContainerCSS}>
                  <div
                    css={[
                      addButtonCSS,
                      css`
                        background: ${colors.gray100};
                        border-radius: 10px 10px 0 0;
                      `,
                    ]}
                  >
                    수강생 앱 미설치시
                  </div>
                  <Button
                    onClick={() => navigate("/crm/user-manage/create/guest")}
                    css={[
                      addButtonCSS,
                      css`
                        background: white;
                        border-radius: 10px;
                      `,
                    ]}
                  >
                    비회원으로 추가
                  </Button>
                </div>
              </div>
            )}
            <Button
              variant="primary"
              size={46}
              onClick={() => setIsAddOpen(!isAddOpen)}
            >
              {isAddOpen ? "취소하기" : "수강권 추가"}
              {!isAddOpen && (
                <IcPlus
                  css={css`
                    color: ${colors.black};
                  `}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
      <AddMemberByEmailDialog
        isEmailInputOpen={isEmailInputOpen}
        setIsEmailInputOpen={setIsEmailInputOpen}
        setIsEmailInputError={setIsEmailInputError}
      />
      <DialogPopup
        isOpen={isEmailInputError}
        setIsOpen={setIsEmailInputError}
        title={"존재하지 않는 회원입니다"}
        body={"회원앱> 마이(탭) >이메일 이용해주세요"}
      />
    </div>
  );
}

const listContainerCSS = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  row-gap: 10px;
  column-gap: 20px;
`;

const addButtonCSS = css`
  padding: 12px 16px;
  width: 100%;
  ${typography.mobile.body2};
  text-align: center;
`;

const addButtonItemContainerCSS = css`
  border-radius: 10px;
  border: 1px solid ${colors.gray50};
`;

const addButtonContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const addTicketButtonContainerCSS = css`
  position: sticky;
  bottom: 100px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 160px;
`;

const contentContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  position: relative;
  align-items: end;
  gap: 20px;
`;

const textAreaCSS = css`
  width: 100%;
  height: 20px;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;
