import React from "react";
import { css } from "@emotion/react";
import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";
import {
  fontFamily,
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import Button from "design-system/components/Button/Button";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../hoock/useAmplitude";
import { useNavigate } from "react-router";

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export function Sidebar({ isOpen, setIsOpen }: SidebarProps) {
  const navigate = useNavigate();
  const { sendClickEvent } = useAmplitude();

  return (
    <div css={sidebarCSS(isOpen)}>
      <div css={headerContainerCSS}>
        <div css={mobileHeaderTestCSS}>FitsYou</div>
        <Button css={closeButtonCSS} onClick={() => setIsOpen(!isOpen)}>
          <IcClose />
        </Button>
      </div>
      <nav css={menuListCSS}>
        <Button
          variant={"text"}
          css={menuTextCSS}
          onClick={() => {
            sendClickEvent({
              eventName: EventName.web_search_trainer_button_clicked,
              screenName: ScreenName.web_home_main,
            });
            navigate("/trainer/list");
            setIsOpen(false);
          }}
        >
          트레이너 찾기
        </Button>
        <Button
          variant={"text"}
          css={menuTextCSS}
          onClick={() => {
            sendClickEvent({
              eventName: EventName.web_search_trainer_button_clicked,
              screenName: ScreenName.web_home_main,
            });
            navigate("/trainer/manage");
            setIsOpen(false);
          }}
        >
          PT 관리
        </Button>
      </nav>
    </div>
  );
}

const menuTextCSS = css`
  width: fit-content;
  color: white;
  ${typography.Body3}
`;

const mobileHeaderTestCSS = css`
  color: #fff;
  font-size: 20px;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-weight: ${fontWeight.Bold};
  text-align: start;
`;

const headerContainerCSS = css`
  display: flex;
  justify-content: space-between;
`;

// 스타일 정의
const sidebarCSS = (isOpen: boolean) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); /* 어두운 배경 */
  color: white;
  padding: 20px;
  transform: ${isOpen ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 60px;
  //align-items: center;
  //justify-content: center;
`;

const menuButtonCSS = css`
  position: fixed;
  top: 20px;
  left: 20px;
  background: black;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  z-index: 1100;
  font-size: 24px;
`;

const closeButtonCSS = css`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const menuListCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 24px;

  a {
    color: white;
    text-decoration: none;
  }
`;
