import { useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getProfilesIdDetail } from "api/trainer/profiles/profiles";
import { queryKeys } from "libs/react-query";

import { css } from "@emotion/react";
import { MainHeader } from "common/Header/MainHeader";
import { TrainerProfileInfo } from "./component/TrainerProfileInfo/TrainerProfileInfo";
import { PriceInfo } from "./component/PriceInfo/PriceInfo";
import { getWidthMediaQuery } from "../../styles/mediaQueries";

export function TrainerDetailPage() {
  const { trainerId } = useParams();

  const { data: trainerDetail } = useQuery({
    queryFn: () => getProfilesIdDetail({ trainerId: Number(trainerId) }),
    queryKey: queryKeys.getProfilesIdDetail({ trainerId: Number(trainerId) }),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={containerCSS}>
      <MainHeader />
      <div css={contentContainerCSS}>
        <TrainerProfileInfo />
        {!!trainerDetail?.trainer.prices.prices.length && <PriceInfo />}
      </div>
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  background: black;
  width: 100%;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 36px;

  ${getWidthMediaQuery("pc")} {
    padding: 120px 207px 60px;
    gap: 72px;
  }
`;
