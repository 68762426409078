import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { TrainerProfile } from "../TrainerProfile/TrainerProfile";
import { BestPTReportDTO } from "../BestPractice/BestPracticesDTO";
import { ImageView } from "design-system/components/ImageView/ImageView";
import { getWidthMediaQuery } from "../../../styles/mediaQueries";

interface PtReportProps {
  report: BestPTReportDTO;
}

export function PtReport({ report }: PtReportProps) {
  return (
    <div css={containerCSS}>
      <div css={titleContainerCSS}>
        <div css={titleTextCSS}>베스트 PT 일지</div>
        <div css={titleTextCSS}>
          회원 관리에 진심인 트레이너와 변화를 함께 기록합니다
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 24px;
        `}
      >
        <TrainerProfile trainer={report.trainer} />
        {/*TODO:JUN 수정 필요*/}
        <div css={reportImageContainerCSS}>
          {report.reportList.map((report) => {
            return (
              <ImageView src={report} css={imageCSS} isClickable={false} />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const titleContainerCSS = css`
  padding: 16px;
`;

const imageCSS = css`
  width: 328px;
  height: 328px;
`;

const reportImageContainerCSS = css`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

const titleTextCSS = css`
  ${typography.Body3};
  color: white;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${getWidthMediaQuery("pc")} {
    gap: 60px;
  }
`;
