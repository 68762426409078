import React from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { ImageView } from "design-system/components/ImageView/ImageView";

export interface LessonSignParams {
  signImageUrl?: string;
}

export function LessonSign({ signImageUrl }: LessonSignParams) {
  return (
    <div css={containerCSS}>
      <div css={descriptionContainerCSS}>
        <span css={signTextCSS}>사인</span>
        <span css={signDescriptionTextCSS}>
          사인은 트레이너앱(FityouTrainer)에서 가능합니다
        </span>
      </div>
      {signImageUrl && (
        <ImageView src={signImageUrl} css={signImageCSS} variant={"square"} />
      )}
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const descriptionContainerCSS = css`
  display: flex;
  gap: 8px;
  align-items: end;
`;

const signTextCSS = css`
  ${typography.mobile.heading3};
  color: ${colors.gray600};
`;

const signDescriptionTextCSS = css`
  ${typography.mobile.body1};
  color: ${colors.gray400};
`;

const signImageCSS = css`
  border: solid 1px ${colors.gray200};
  padding: 5px;
`;
