import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getProfilesIdDetail } from "api/trainer/profiles/profiles";
import { queryKeys } from "libs/react-query";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";

import { useMemo } from "react";
import { PriceInfoItem } from "./component/PriceInfoItem/PriceInfoItem";
import { getWidthMediaQuery } from "styles/mediaQueries";

export function PriceInfo() {
  const { trainerId } = useParams();

  const { data: trainerDetail } = useQuery({
    queryFn: () => getProfilesIdDetail({ trainerId: Number(trainerId) }),
    queryKey: queryKeys.getProfilesIdDetail({ trainerId: Number(trainerId) }),
  });

  const noneTimeSpecial = useMemo(() => {
    return trainerDetail?.trainer.prices.prices.filter(
      ({ timeSpecial }) => !timeSpecial,
    );
  }, [trainerDetail]);

  const lunchTimeSpecial = useMemo(() => {
    return trainerDetail?.trainer.prices.prices.filter(
      ({ timeSpecial }) => timeSpecial?.type === "lunch_time",
    );
  }, [trainerDetail]);

  const weekendTimeSpecial = useMemo(() => {
    return trainerDetail?.trainer.prices.prices.filter(
      ({ timeSpecial }) => timeSpecial?.type === "weekend",
    );
  }, [trainerDetail]);

  const nightTimeSpecial = useMemo(() => {
    return trainerDetail?.trainer.prices.prices.filter(
      ({ timeSpecial }) => timeSpecial?.type === "dead_of_night",
    );
  }, [trainerDetail]);

  return (
    <div css={containerCSS}>
      {noneTimeSpecial && <PriceInfoItem prices={noneTimeSpecial} />}
      {!!lunchTimeSpecial?.length && (
        <PriceInfoItem timeSpecial="lunch_time" prices={lunchTimeSpecial} />
      )}
      {!!weekendTimeSpecial?.length && (
        <PriceInfoItem timeSpecial="weekend" prices={weekendTimeSpecial} />
      )}
      {!!nightTimeSpecial?.length && (
        <PriceInfoItem timeSpecial="dead_of_night" prices={nightTimeSpecial} />
      )}
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  color: ${colors.gray25};
  padding: 36px 16px;
  gap: 36px;

  ${getWidthMediaQuery("pc")} {
    padding: 0 36px;
    gap: 72px;
  }
`;
