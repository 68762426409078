import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

export async function urlToFile(
  url: string,
  fileName: string,
  mimeType: string,
) {
  const response = await fetch(url);

  // URL에서 데이터를 가져오고 Blob으로 변환
  const blob = await response.blob();

  // Blob을 File로 변환
  const file = new File([blob], fileName, { type: mimeType });
  return file;
}

export const extractVideoThumbnailUrl = (
  fileURL: string,
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");

    video.src = fileURL;
    video.currentTime = 1; // 1초로 설정하여 첫 프레임 추출

    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL("image/png"));
      } else {
        resolve(undefined);
      }
    };

    video.onerror = (error) => {
      reject(error);
    };
  });
};

export const extractVideoThumbnailFile = (
  videoFile: File,
): Promise<File | undefined> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");

    // File 객체를 URL로 변환하여 video 요소에 설정
    const videoURL = URL.createObjectURL(videoFile);
    video.src = videoURL;
    video.currentTime = 1; // 1초로 설정하여 첫 프레임 추출

    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Blob으로 변환하여 File 객체 생성
        canvas.toBlob((blob) => {
          if (blob) {
            const thumbnailFile = new File([blob], "thumbnail.png", {
              type: "image/png",
            });
            resolve(thumbnailFile);
          } else {
            resolve(undefined);
          }

          // 생성된 URL 해제
          URL.revokeObjectURL(videoURL);
        }, "image/png");
      } else {
        resolve(undefined);
        URL.revokeObjectURL(videoURL); // URL 해제
      }
    };

    video.onerror = () => {
      reject(new Error("Video loading failed"));
      URL.revokeObjectURL(videoURL); // URL 해제
    };
  });
};

export const convertVideoToMp4 = async (file: File) => {
  const ffmpeg = new FFmpeg();
  console.log("000");
  ffmpeg.on("log", ({ type, message }) => {
    console.log("타입: " + type + "///" + message);
  });

  if (file.type === "video/mp4") {
    return file;
  }

  await ffmpeg.load();
  await ffmpeg.writeFile("input", await fetchFile(file));
  // await ffmpeg.exec(["-i", "input", "-preset", "ultrafast", "output.mp4"]);

  // 변환 시간 측정 시작
  const startTime = Date.now();

  await ffmpeg.exec([
    "-i",
    "input", // 입력 파일
    "-r",
    "20", // 프레임 레이트 설정 (속도 증가를 위해 낮춘 값)
    "-preset",
    "ultrafast", // 초고속 인코딩 설정
    "-crf",
    "35", // 품질과 속도의 균형을 위한 CRF 설정
    "-tune",
    "zerolatency", // 실시간 스트리밍용 초저지연 인코딩
    "output.mp4", // 출력 파일
  ]);

  // 변환 시간 측정 종료
  const endTime = Date.now();
  console.log("변환에 걸린 시간:", (endTime - startTime) / 1000, "초");

  const data = await ffmpeg.readFile("output.mp4");
  return new File([data], "output.mp4", { type: "video/mp4" });
};

export const convertImageToPng = async (file: File) => {
  return new Promise<File>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Failed to get 2D context"));
        return;
      }

      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(new File([blob], "output.png", { type: "image/png" }));
        } else {
          reject(new Error("Failed to convert image to PNG"));
        }
      }, "image/png");
    };

    img.onerror = (error) => reject(error);
  });
};
