import React, { useState } from "react";
import { css } from "@emotion/react";
import Button from "design-system/components/Button/Button";
import { typography } from "design-system/styles/typography/typography";
import mobileEx1 from "resource/trainer/homeResource/mobile1.png";
import mobileEx2 from "resource/trainer/homeResource/mobile2-1.png";
import mobileEx2_2 from "resource/trainer/homeResource/mobile2-2.png";
import memberImg1 from "resource/trainer/homeResource/인스타 17.png";
import memberImg2 from "resource/trainer/homeResource/인스타 18.png";
import memberImg3 from "resource/trainer/homeResource/인스타 19.png";
import memberImg4 from "resource/trainer/homeResource/인스타 20.png";
import pc1img from "resource/trainer/homeResource/homepage_pc1.png";
import pc2img from "resource/trainer/homeResource/pc2.png";
import gymImgBG from "resource/trainer/homeResource/homepage_middle.png";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../hoock/useAmplitude";
import { getWidthMediaQuery } from "../../../styles/mediaQueries";
import { colors } from "design-system/styles/colors";

export enum DescriptionType {
  trainer_pc = "trainer_pc",
  trainer_mobile = "trainer_mobile",
  member_mobile = "member_mobile",
}

const trainerPCTitle = "PC 환경에서도 \n일지 조회 및 작성을 진행 할 수 있어요.";
const trainerMobileTitle =
  "일지 작성은 물론\n일정 관리까지 모바일로 쉽고 빠르게";
const memberMobileTitle =
  "PT 관리 일지부터 개인운동 기록 및\n루틴 관리까지 쉽고 빠르게";
const memberMobileDescription1 =
  "지난 수업 내용, 모두 기억하시나요?\nPT일지, 이제는 선택이 아니라 필수입니다.\n지금 트레이너님과 함께 핏츠유로 기록해보세요.";
const memberMobileDescription2 =
  "배운 루틴을 다시 해보고 싶지만 기억이 가물가물...\n개인 운동 루틴 짜는 것, 막막하지 않으세요?\n진행했던 루틴 그대로 가져와서 사용해보세요.";
const memberMobileDescription3 =
  "모두가 다르듯,\n나에게 맞는 운동법도 다르지 않을까요?\n트레이너님이 남겨준 개인 맞춤 설명을 활용하세요";
const memberMobileDescription4 =
  "운동 진행상황, 눈에 보이면 더 좋을텐데..\n나의 운동기록, 사진 및 동영상 피드백과 그래프로\n한눈에 확인하세요.";

export function DescriptionFrame() {
  const [selectedType, setSelectedType] = useState<DescriptionType>(
    DescriptionType.member_mobile,
  );
  const { sendScreenViewEvent } = useAmplitude();

  return (
    <div
      css={css`
        background-color: black;
        position: relative;
      `}
    >
      <div css={containerCSS}>
        <div css={buttonContainerCSS}>
          <Button
            css={[
              buttonCSS,
              selectedType == DescriptionType.trainer_pc && activeButtonCSS,
            ]}
            onClick={() => {
              setSelectedType(DescriptionType.trainer_pc);
              sendScreenViewEvent({
                eventName: EventName.web_home_contents_description_clicked,
                screenName: ScreenName.web_home_main,
                eventProperties: {
                  description_type: "pc",
                },
              });
            }}
          >
            트레이너(PC)
          </Button>
          <Button
            css={[
              buttonCSS,
              selectedType == DescriptionType.trainer_mobile && activeButtonCSS,
            ]}
            onClick={() => {
              setSelectedType(DescriptionType.trainer_mobile);
              sendScreenViewEvent({
                eventName: EventName.web_home_contents_description_clicked,
                screenName: ScreenName.web_home_main,
                eventProperties: {
                  description_type: "mobile",
                },
              });
            }}
          >
            트레이너(MOBILE)
          </Button>
          <Button
            css={[
              buttonCSS,
              selectedType == DescriptionType.member_mobile && activeButtonCSS,
            ]}
            onClick={() => {
              setSelectedType(DescriptionType.member_mobile);
              sendScreenViewEvent({
                eventName: EventName.web_home_contents_description_clicked,
                screenName: ScreenName.web_home_main,
                eventProperties: {
                  description_type: "mobile",
                },
              });
            }}
          >
            회원(MOBILE)
          </Button>
        </div>
        <div
          css={css`
            position: relative;
            margin: 39px 0;
          `}
        >
          <img src={gymImgBG} css={gymImgBGCSS} />
          <div css={descriptionCSS}>
            <div css={titleCSS}>
              {selectedType == DescriptionType.trainer_pc && trainerPCTitle}
              {selectedType == DescriptionType.trainer_mobile &&
                trainerMobileTitle}
              {selectedType == DescriptionType.member_mobile &&
                memberMobileTitle}
            </div>
          </div>
          )
        </div>
        {selectedType == DescriptionType.trainer_pc && (
          <div css={pcImageContainerCSS}>
            <img src={pc1img} css={pcImage1CSS} />
            <img src={pc2img} css={pcImage2CSS} />
          </div>
        )}
        {selectedType == DescriptionType.trainer_mobile && (
          <div css={mobileImageContainerCSS}>
            <div>
              <img src={mobileEx1} css={mobileImageCSS} />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                padding-top: 170px;
                gap: 200px;
              `}
            >
              <img src={mobileEx2} css={mobileImageCSS} />
              <img src={mobileEx2_2} css={mobileImageCSS} />
            </div>
          </div>
        )}

        {selectedType == DescriptionType.member_mobile && (
          <div css={memberDescriptionContainerCSS}>
            <div css={memberDescriptionItemContainerCSS}>
              <div>
                <div css={memberDescriptionTextContainerCSS}>
                  <div css={memberDescriptionTitleCSS}>PT 일지</div>
                  <div css={memberDescriptionTextCSS}>
                    {memberMobileDescription1}
                  </div>
                </div>
              </div>
              <img src={memberImg1} css={instagramImgCSS} />
            </div>
            <div css={memberDescriptionItemContainerCSS}>
              <div css={memberDescriptionTextContainerCSS}>
                <div css={memberDescriptionTitleCSS}>PT루틴 불러오기</div>
                <div css={memberDescriptionTextCSS}>
                  {memberMobileDescription2}
                </div>
              </div>
              <img src={memberImg2} css={instagramImgCSS} />
            </div>
            <div css={memberDescriptionItemContainerCSS}>
              <div css={memberDescriptionTextContainerCSS}>
                <div css={memberDescriptionTitleCSS}>맞춤 운동법</div>
                <div css={memberDescriptionTextCSS}>
                  {memberMobileDescription3}
                </div>
              </div>
              <img src={memberImg3} css={instagramImgCSS} />
            </div>
            <div css={memberDescriptionItemContainerCSS}>
              <div css={memberDescriptionTextContainerCSS}>
                <div css={memberDescriptionTitleCSS}>운동 변화 추적</div>
                <div css={memberDescriptionTextCSS}>
                  {memberMobileDescription4}
                </div>
              </div>
              <img src={memberImg4} css={instagramImgCSS} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const memberDescriptionTextContainerCSS = css`
  display: flex;
  flex-direction: column;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const memberDescriptionContainerCSS = css`
  color: white;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${getWidthMediaQuery("pc")} {
    gap: 200px;
    width: 100%;
    align-items: center;
  }
`;

const memberDescriptionItemContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${getWidthMediaQuery("pc")} {
    padding: 0 220px;
    gap: 70px;
  }
`;

const memberDescriptionTitleCSS = css`
  ${typography.mobile.heading3}
  ${getWidthMediaQuery("pc")} {
    ${typography.Body1}
  }
`;

const memberDescriptionTextCSS = css`
  ${typography.mobile.body2};
  color: ${colors.gray200};

  ${getWidthMediaQuery("pc")} {
    ${typography.Body3}
    color: ${colors.gray100};
  }
`;

const gymImgBGCSS = css`
  width: 100%;
  overflow: hidden;

  ${getWidthMediaQuery("pc")} {
    max-height: 422px;
  }
`;

const instagramImgCSS = css`
  max-width: 800px;
  max-height: 800px;
`;

const pcImageContainerCSS = css`
  width: 100%;
  display: flex;
  gap: 70px;
  flex-direction: column;
  align-items: center;

  ${getWidthMediaQuery("pc")} {
    gap: 256px;
  }
`;
const pcImage1CSS = css`
  width: 330px;
  object-fit: contain;

  ${getWidthMediaQuery("pc")} {
    width: 905px;
  }
`;
const pcImage2CSS = css`
  width: 90vw;
  object-fit: contain;

  ${getWidthMediaQuery("pc")} {
    margin-left: 400px;
    width: 1400px;
  }
`;

const mobileImageCSS = css`
  width: 250px;
  object-fit: contain;

  ${getWidthMediaQuery("pc")} {
    width: 300px;
  }
`;

const mobileImageContainerCSS = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    align-items: normal;
    gap: 203px;
  }
`;

const descriptionCSS = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

const titleCSS = css`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: -2.4px;

  ${getWidthMediaQuery("pc")} {
    font-size: 60px;
  }
`;

const containerCSS = css`
  padding: 60px 0 150px 0;
  z-index: 2;
  position: relative;

  ${getWidthMediaQuery("pc")} {
    padding: 200px 0 150px 0;
  }
`;

const buttonContainerCSS = css`
  display: flex;
  justify-content: center;

  ${getWidthMediaQuery("pc")} {
    gap: 80px;
    padding-left: 170px;
    justify-content: left;
  }
`;

const buttonCSS = css`
  color: #a5a5a5; /* 기본 글자색 */
  background: none;

  ${typography.mobile.body1}
  ${getWidthMediaQuery("pc")} {
    ${typography.mobile.heading1}
  }
`;

const activeButtonCSS = css`
  color: white; /* 선택된 버튼의 글자색 */
  text-decoration: underline;
  background: none;
  text-underline-offset: 10px;
`;
