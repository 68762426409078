import { HomeTrainerInfo } from "../BestPractice/BestPracticesDTO";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { ImageView } from "design-system/components/ImageView/ImageView";
import { typography } from "design-system/styles/typography/typography";
import { useNavigate } from "react-router";
import { getWidthMediaQuery } from "../../../styles/mediaQueries";

interface TrainerProfileProps {
  trainer: HomeTrainerInfo;
}

export function TrainerProfile({ trainer }: TrainerProfileProps) {
  const navigate = useNavigate();
  return (
    <div
      css={containerCSS}
      onClick={() => {
        navigate("/trainer/list");
      }}
    >
      <ImageView
        src={trainer.thumbNail}
        variant={"circle"}
        css={imageViewCSS}
      />
      <div css={contentContainerCSS}>
        <div css={nameText}>{trainer.name}</div>
        <div
          css={css`
            ${typography.mobile.body2}
          `}
        >
          {trainer.place}
        </div>
      </div>
    </div>
  );
}

const imageViewCSS = css`
  height: 64px;
  width: 64px;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const nameText = css`
  ${typography.mobile.heading3}
`;

const containerCSS = css`
  padding: 16px;
  gap: 16px;
  background: ${colors.gray800};
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  color: ${colors.gray25};
  margin: 0 16px;

  &:hover {
    background-color: ${colors.lime600};
    color: ${colors.gray800};
  }

  ${getWidthMediaQuery("pc")} {
    width: 100%;
  }
`;
