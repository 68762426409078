import {
  PricePriceDTO,
  TimeSpecialType,
  timeSpecialTypeInKR,
} from "api/admin/adminTypes";

import { InfoBadge } from "trainer/[trainerId]/component/InfoBadge/InfoBadge";
import { css } from "@emotion/react";
import {
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";

interface PriceInfoItemInterface {
  timeSpecial?: TimeSpecialType;
  prices: PricePriceDTO[];
}

export function PriceInfoItem({ timeSpecial, prices }: PriceInfoItemInterface) {
  return (
    <>
      <div css={titleContainerCSS}>
        <InfoBadge text={!timeSpecial ? "레슨 이용 가격" : "특가"} />
        <span>
          {timeSpecial && (
            <span css={timeSpecialTitleTextCSS}>
              {timeSpecialTypeInKR(timeSpecial)}
            </span>
          )}
          {"1:1 PT 이용권"}
        </span>
      </div>
      <div>
        {prices.map(({ cycle, price }, i) => {
          return (
            <div key={i} css={priceItemContainerCSS}>
              <div>{`${cycle}회`}</div>
              <div css={priceContainerCSS}>
                <span>
                  {"회당 "}
                  <span css={priceCSS}>{price.toLocaleString()}</span>
                  {"원"}
                </span>
                <div>{`${(price * cycle).toLocaleString()}원`}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

const titleContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${typography.Body1};
`;

const timeSpecialTitleTextCSS = css`
  color: ${colors.lime600};
`;

const priceItemContainerCSS = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  border-top: 1px solid ${colors.gray800};
  border-bottom: 1px solid ${colors.gray800};
  color: ${colors.gray100};
  ${typography.Body3};
  font-weight: ${fontWeight.Medium};
  align-items: center;
`;

const priceContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const priceCSS = css`
  color: ${colors.gray25};
  ${typography.Body2};
`;
