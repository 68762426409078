import { useContext, useState } from "react";
import trainerSearchListContext from "../trainerSearchListContext";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import Button from "design-system/components/Button/Button";
import * as Dialog from "design-system/components/Dialog/Dialog";
import DaumPostcode from "react-daum-postcode";
import closeIcon from "design-system/components/atom/IconResource/ic_close.svg";
import IcClose from "design-system/components/atom/IconResource/Icon/IcClose";
import { ImageView } from "design-system/components/ImageView/ImageView";
import img from "design-system/components/atom/IconResource/recommend_img.png";

export function TrainerLocationButton() {
  const [isOpen, setIsOpen] = useState(false);
  const { address, setAddress } = useContext(trainerSearchListContext);

  return (
    <>
      <div css={containerCSS}>
        <ImageView src={img} styles={iconCSS} isClickable={false} />
        <div css={locationButtonContainerCSS}>
          <Button
            css={locationButtonCSS}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {address ? "위치 재설정하기" : "내 주변 PT 찾기"}
          </Button>
          {address && (
            <div css={addressCSS}>
              <span>{`내 위치 : ${address}`}</span>
              <Button
                variant="icon"
                styles={locationDeleteButtonCSS}
                onClick={() => setAddress(undefined)}
              >
                <IcClose />
              </Button>
            </div>
          )}
        </div>
      </div>
      <Dialog.Root variant={"page"} open={isOpen}>
        <Dialog.Content css={dialogCSS}>
          <div css={loginPopupContainerCSS}>
            <div css={loginPopupHeaderContainerCSS}>
              <img
                src={closeIcon}
                width={38}
                height={38}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            </div>
          </div>
          <DaumPostcode
            style={{ width: "100%", height: "100%" }}
            onComplete={(result) => {
              setAddress(result.roadAddress);
              setIsOpen(false);
            }}
          />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;

const iconCSS = css`
  width: 66px;
  height: 53px;
`;

const locationButtonContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const locationButtonCSS = css`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  color: white;
  ${typography.mobile.heading2};
  width: fit-content;
  padding: 16px 24px;
`;

const addressCSS = css`
  display: flex;
  gap: 8px;
  color: ${colors.gray400};
  ${typography.mobile.body1}
`;

const locationTextCSS = css`
  display: flex;
  align-items: center;
`;

const locationDeleteButtonCSS = css`
  color: ${colors.gray400};
`;

const dialogCSS = css`
  height: 750px;
  width: 600px;
  padding: 5px 0 15px 0;
`;

const loginPopupContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const loginPopupHeaderContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;
