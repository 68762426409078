import { PtReport } from "../PtReport/PtReport";
import React from "react";
import { bestPTReportList } from "./ptReportDummy";
import { css } from "@emotion/react";
import { getWidthMediaQuery } from "../../../styles/mediaQueries";

export function BestPTReport() {
  const reportList = bestPTReportList;
  const focusedReport = reportList[0];
  return (
    <div css={containerCSS}>
      <PtReport report={focusedReport} />
    </div>
  );
}

const containerCSS = css`
  z-index: 1;
  margin: 16px auto;

  ${getWidthMediaQuery("pc")} {
    width: 1000px;
    margin: 80px auto;
  }
`;
