import { useEffect, useState } from "react";
import { queryClient, queryKeys } from "libs/react-query";

import { css } from "@emotion/react";
import { MainHeader } from "common/Header/MainHeader";
import TrainerSearchListContext from "./trainerSearchListContext";
import { getWidthMediaQuery } from "../../styles/mediaQueries";
import { TrainerList } from "./component/TrainerList";
import { TrainerLocationButton } from "./component/TrainerLocationButton";

export function TrainerSearchListPage() {
  const [address, setAddress] = useState<string | undefined>();

  useEffect(() => {
    queryClient.invalidateQueries([queryKeys.getList({ address })]);
  }, [address]);

  return (
    <div css={containerCSS}>
      <TrainerSearchListContext.Provider value={{ address, setAddress }}>
        <MainHeader />
        <div css={contentContainerCSS}>
          <TrainerLocationButton />
          <TrainerList />
        </div>
      </TrainerSearchListContext.Provider>
    </div>
  );
}

const containerCSS = css`
  display: flex;
  flex-direction: column;
  background: black;
  padding-bottom: 0;
  width: 100%;
  overflow: hidden;
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 80px 0 180px;

  ${getWidthMediaQuery("pc")} {
    gap: 100px;
    padding: 80px 234px 180px;
  }
`;
