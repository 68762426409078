import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getProfilesIdDetail } from "api/trainer/profiles/profiles";
import { queryKeys } from "libs/react-query";
import { useContext, useMemo } from "react";
import { centerDistanceWithUnit, degreeTypeInKR } from "api/admin/adminTypes";

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { getWidthMediaQuery } from "styles/mediaQueries";
import Button from "design-system/components/Button/Button";
import { ImageView } from "design-system/components/ImageView/ImageView";
import avatarImage from "design-system/components/atom/IconResource/avatar_image.svg";
import arrowIcon from "design-system/components/atom/IconResource/ic_arrow_naviright.svg";
import trainerSearchListContext from "trainer/list/trainerSearchListContext";
import { useSearchParams } from "react-router-dom";

export function TrainerProfileInfo() {
  const { trainerId } = useParams();
  const { address } = useContext(trainerSearchListContext);
  const [searchParams] = useSearchParams();
  const distance = parseInt(searchParams.get("dist") || "0", 10);

  const { data: trainerDetail } = useQuery({
    queryFn: () =>
      getProfilesIdDetail({ trainerId: Number(trainerId), address }),
    queryKey: queryKeys.getProfilesIdDetail({
      trainerId: Number(trainerId),
      address,
    }),
  });

  const educations = useMemo(() => {
    return trainerDetail?.trainer.qualifications.filter(
      ({ qualificationType }) => qualificationType === "education",
    );
  }, [trainerDetail]);

  const awards = useMemo(() => {
    return trainerDetail?.trainer.qualifications.filter(
      ({ qualificationType }) => qualificationType === "awards",
    );
  }, [trainerDetail]);

  const certificataions = useMemo(() => {
    return trainerDetail?.trainer.qualifications.filter(
      ({ qualificationType }) => qualificationType === "certification",
    );
  }, [trainerDetail]);

  return (
    <div css={infoContainerCSS}>
      <div css={profileContainerCSS}>
        <div css={profileImageContainerCSS}>
          <ImageView
            src={trainerDetail?.trainer.mainImage?.url ?? avatarImage}
            variant="circle"
            styles={profileMainImageCSS}
          />
          <div css={profileSubImageContainerCSS}>
            {trainerDetail?.trainer.images.map(({ id, url }) => (
              <ImageView
                key={id}
                src={url}
                variant="circle"
                styles={profileSubImageCSS}
              />
            ))}
          </div>
        </div>
        <div css={profileTextContainerCSS}>
          <div css={profileBasicInfoContainerCSS}>
            <div css={profileTextTitleContainerCSS}>
              <div
                css={profileTitleCSS}
              >{`${trainerDetail?.trainer.name} 트레이너`}</div>
              {trainerDetail?.trainer.centerInfo && (
                <div>
                  <span>{trainerDetail.trainer.centerInfo.name}</span>
                  {distance && (
                    <span>{` | ${centerDistanceWithUnit(distance)}`}</span>
                  )}
                </div>
              )}
            </div>
            {trainerDetail?.trainer.selfIntroduction && (
              <div>{trainerDetail.trainer.selfIntroduction}</div>
            )}
          </div>
          <div css={profileDetailInfoContainerCSS}>
            {trainerDetail?.trainer.event && (
              <div css={profileDetailInfoCSS}>
                <div css={profileDetailInfoTitleCSS}>
                  <span>{`${trainerDetail?.trainer.name} 트레이너님만의 `}</span>
                  <span css={profileDetailInfoTitleHighlightCSS}>
                    {"이벤트 🎉"}
                  </span>
                </div>
                {trainerDetail?.trainer.event}
              </div>
            )}
            <div css={profileDetailInfoSubContrainerCSS}>
              {!!trainerDetail?.trainer.qualifications.length && (
                <div css={profileDetailInfoCSS}>
                  <div css={profileDetailInfoTitleCSS}>{"트레이너 이력"}</div>
                  {!!educations?.length && (
                    <div>
                      <div>{"학력"}</div>
                      <ul css={profileDetailInfoListCSS}>
                        {educations.map(
                          ({ id, university, department, degree }) => (
                            <li
                              key={id}
                            >{`${university} ${department} ${degree && degreeTypeInKR(degree)}`}</li>
                          ),
                        )}
                      </ul>
                    </div>
                  )}
                  {!!awards?.length && (
                    <div>
                      <div>{"수상경력"}</div>
                      <ul css={profileDetailInfoListCSS}>
                        {awards.map(({ id, title }) => (
                          <li key={id}>{title}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!!certificataions?.length && (
                    <div>
                      <div>{"자격증"}</div>
                      <ul css={profileDetailInfoListCSS}>
                        {certificataions.map(({ id, title }) => (
                          <li key={id}>{title}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {trainerDetail?.trainer.centerInfo && (
                <div css={profileDetailInfoCSS}>
                  <div css={profileDetailInfoTitleCSS}>
                    <span>{"근무 센터"}</span>
                    {trainerDetail.trainer.centerInfo.name && (
                      <span>{` | ${trainerDetail.trainer.centerInfo.name}`}</span>
                    )}
                  </div>
                  <div>
                    {trainerDetail.trainer.centerInfo.tel && (
                      <div>{`전화번호 | ${trainerDetail.trainer.centerInfo.tel}`}</div>
                    )}
                    {trainerDetail.trainer.centerInfo.address && (
                      <div>
                        <span>{`주소 | ${trainerDetail.trainer.centerInfo.address}`}</span>
                        {trainerDetail.trainer.centerInfo.additionalInfo && (
                          <span>{` ${trainerDetail.trainer.centerInfo.additionalInfo}`}</span>
                        )}
                      </div>
                    )}
                  </div>
                  {/* TODO: 가까운 위치 순위 정보 생기면 추가 @sello, @jun */}
                  {/* <div css={profileLocationTextCSS}>
                  <span>{"내 위치에서 "}</span>
                  <span css={profileLocationHighlightTextCSS}>{"N"}</span>
                  <span>{"번째로 가까운 트레이너에요!"}</span>
                </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Button variant="primary" size={46} styles={buttonCSS}>
        {"맞춤 운동루틴 요청하기"}
        <img src={arrowIcon} />
      </Button>
    </div>
  );
}

const infoContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colors.gray900};
  gap: 34px;
  padding: 40px 16px;

  ${getWidthMediaQuery("pc")} {
    padding: 50px 60px;
    gap: 30px;
  }
`;

const profileContainerCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  ${getWidthMediaQuery("pc")} {
    gap: 38px;
  }
`;

const profileImageContainerCSS = css`
  display: flex;
  gap: 20px;
  overflow: scroll;

  ${getWidthMediaQuery("pc")} {
    gap: 30px;
  }
`;

const profileMainImageCSS = css`
  width: 100px;
  height: 100px;
  border-radius: 100px;
`;

const profileSubImageCSS = css`
  width: 60px;
  height: 60px;
  border-radius: 62px;
`;

const profileSubImageContainerCSS = css`
  display: flex;
  gap: 20px;
  align-items: flex-end;

  ${getWidthMediaQuery("pc")} {
    gap: 30px;
  }
`;

const profileTextContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${colors.gray400};
  ${typography.mobile.body1};

  ${getWidthMediaQuery("pc")} {
    gap: 50px;
  }
`;

const profileBasicInfoContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const profileTextTitleContainerCSS = css`
  display: flex;
  gap: 16px;
  align-items: baseline;
`;

const profileTitleCSS = css`
  color: ${colors.lightGray};
  ${typography.mobile.heading1}
`;

const profileDetailInfoContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${getWidthMediaQuery("pc")} {
    gap: 20px;
  }
`;

const profileDetailInfoSubContrainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    gap: 20px;
  }
`;

const profileDetailInfoCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 20px;
  border-radius: 5px;
  background-color: ${colors.gray800};
`;

const profileDetailInfoTitleCSS = css`
  color: ${colors.lightGray};
  ${typography.mobile.heading1};
`;

const profileDetailInfoTitleHighlightCSS = css`
  color: ${colors.lime600};
`;

const profileDetailInfoListCSS = css`
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 16px;
`;

const buttonCSS = css`
  align-self: flex-end;
  width: 100%;

  ${getWidthMediaQuery("pc")} {
    width: auto;
  }
`;

const profileLocationTextCSS = css`
  color: ${colors.gray200};
  ${typography.mobile.heading2};
`;

const profileLocationHighlightTextCSS = css`
  color: ${colors.lime600};
`;
