import { BestRoutineDTO, HomeTrainerInfo } from "./BestPracticesDTO";
import trainerThumbnail1 from ".//piggy/이시원.png";

export const bestRoutineList: BestRoutineDTO[] = [
  {
    title: "재활 pt 및 초보자들의 실종된 엉덩이 근육 살리기",
    workoutList: [
      {
        name: "크램쉘",
        description:
          "옆으로 누워 새우잠 자듯이 눕고 팔꿈치는 세워 \n" +
          "몸의 정렬을 일자로 맞춘다. \n" +
          "발을 구부려 조개가 껍질을 벌리듯 열어주며 엉덩이에 자극을 느낀다.",
        set: 3,
        thumbNail: "",
        src: "",
        count: 15,
      },
      {
        name: "힙에어플레인",
        description:
          "힙힌지 동작으로 한 발을 벽에 두고 고관절을 기울이며 \n" +
          "지지하고 있는 발에 반대에 발을 고관절을 열어준다. \n" +
          "지지하고 있는 발에 엉덩이 근육의 자극을 느끼고 \n" +
          "반대 발은 내전근과 고관절의 가동성을 증진시키며 \n" +
          "발의 인지능력 개선에 도움이 된다.",
        set: 3,
        thumbNail: "",
        src: "",
        count: 15,
      },
      {
        name: "이너타이",
        description:
          "최대한 밀착하여 고관절을 붙히고 \n" +
          "고관절을 앞으로(전방경사)기울이면 윗섬유 엉덩이 자극 \n" +
          "고관절을 뒤로(후방경사)기울이면 아랫섬유 엉덩이 자극",
        set: 3,
        thumbNail: "",
        src: "",
        count: 15,
      },
    ],
    androidURl: "",
    iosURL: "",
    description:
      "이 운동의 경우 초보자들도 손쉽게 따라할 수 있으며 직장인으로 엉덩이 근육이 약화되어 몸의 정렬이 틀어지거나 또는 불균형한 몸을 가지신 체형인 분들이 하시면 좋습니다. 또한 발의 인지능력과 고관절의 가동성 증진에도 굉장한 도움을 주는 운동들로 구성하였습니다. \n" +
      "체형 교정도 하며 볼륨감 있는 힙을 만들 수 있습니다.",
    trainer: {
      id: 1,
      name: "이시원",
      addPeople: 20,
      place: "짐코사트 장안점",
      thumbNail: trainerThumbnail1,
    },
  },
];

export const piggyTrainer: HomeTrainerInfo = {
  id: 1,
  name: "이시원",
  addPeople: 20,
  place: "짐코사트 장안점",
  thumbNail: trainerThumbnail1,
};

export const junTrainer: HomeTrainerInfo = {
  id: 2,
  name: "유창후",
  addPeople: 20,
  place: "오프더 팻",
  thumbNail: trainerThumbnail1,
};
